import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import "./PaymentStatus.css";

interface InputProps {
    heading: string;
    subheading?: string;
    bottomText?: string;
    secondary?: string;
    src?: string;
    icon?: any;
}

export default function PaymentStatus(props: InputProps) {
    return (
        <div
            className='d-flex-column-center'
            style={{ gap: '20px', width: '100%', height: '100%' }}>
            <div className='d-flex-column-center' style={{ width: '100%' }}>
                {props.icon ?
                    <img src={props.icon} alt="status-icon" style={{ width: '30%', maxWidth: '100px' }} /> :
                    <Player
                        autoplay
                        loop
                        src={props.src || ''}
                        style={{
                            width: '50%',
                            maxWidth: '800px'
                        }}
                    >
                    </Player>}
            </div>
            <div className='d-flex-column-center'>
                <div>{props.heading}</div>
                <div> {props.subheading} </div>
                <div> {props.secondary} </div>
            </div>

            <div
                className='d-flex-column-center'
                style={{ position: 'fixed', bottom: 0, marginBottom: '20px' }}>
                <span> {props.bottomText} </span>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import enabledCoupon from '../../assets/enabled_discount.png'
import disabledCoupon from '../../assets/disabled_discount.png'
import vectorDown from '../../assets/Vector_down.png'
import { CouponTypes } from '../../enums/CouponTypesEnum';

function NewClickableCard(props:any) {
  const{offer,onCouponClick}=props;
  const[open,setOpen]=useState(false);

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"14px",alignItems:"stretch"}}>
        <div style={{display:"flex",flexDirection:"column",gap:"14px",alignItems:"stretch",padding:"0px 16px",paddingTop:"18px"}}>
            <div style={{display:'flex',alignItems:"center",gap:"8px"}}>
                <img alt="img" src={offer?.isDisabled ? disabledCoupon : enabledCoupon} style={{width:"38px", height: '38px'}} />
                <div style={{display:"flex",flexDirection:"column",gap:"0.2rem"}}>
                    <div style={{fontSize:"16px",fontWeight:"700"}}>{offer?.couponHeader}</div>
                    <div style={{fontSize:"14px", fontWeight:"600",color:offer?.isDisabled?'#E2574C':'#4A8859'}}>{offer?.isDisabled?offer?.errorMessages:(offer.couponType === CouponTypes.FLAT ? `Save flat ₹${offer.couponValue} with this coupon` : `Save up to ₹${offer.maximumDiscountAmount} with this coupon`)}</div>
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",padding:"0rem 0.5rem"}}>
                <div style={{padding:"8px 10px",border:"1px dashed grey",fontWeight:"600",fontSize:"12px"}}>
                    {offer?.couponCode}
                </div>
                <div style={{display:'flex',alignItems:"center",gap:"0.3rem",cursor:"pointer"}} onClick={()=>setOpen(!open)}>
                    <div style={{fontSize:"12px",fontWeight:"600",color:"gray"}}>Details</div>
                    <img alt="img" src={vectorDown} style={{width:"9px",height:"5px",marginTop:"0.3rem"}}/>
                </div>
            </div>
                {
                    open?
                    <ul style={{paddingLeft: '16px', margin: 0}}>
                        {
                            offer?.termsAndConditions.map((condition:any)=>(
                                <li style={{fontSize:"12px",fontWeight:"500",color:"black"}}>{condition}</li>
                            ))
                        }
                    </ul>:
                    <></>
                }
        </div>
        <div style={{
            padding:"12px",
            fontSize:"14px",
            fontWeight:"700",
            border:"none",
            backgroundColor: !offer?.isDisabled?"#FFFAF4":"#F2F2F2",
            color:!offer?.isDisabled?'#FF941A':'#ADADAD',
            textAlign:"center",
            borderBottomLeftRadius:'1.5rem',
            borderBottomRightRadius:'1.5rem',
            cursor:"pointer"
        }}
        onClick={()=>{
            if(!offer?.isDisabled){
                onCouponClick(offer);
            }
        }}>
            Tap to Apply
        </div>
    </div>
  )
}

export default NewClickableCard

import HttpClient from "../HttpClient";
import {TransactionV3} from "../interfaces/transactionV3";
import RazorpayPayment from "../interfaces/razorpayPayment";

export default class DiscountCouponService {

    async getDiscountCouponDetails(orderId: number){
        const res = await HttpClient.get(`/customers/discount-coupon-order/${orderId}`);
        if (res?.status === 200){
            return res?.data;
        }
        return null;
    }

    async initiatePayment(orderId: number): Promise<TransactionV3> {
        const res = await HttpClient.post(`/customers/discount-coupon-order/${orderId}/razorpay-order`);
        return res?.data;
    }

    async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/customers/discount-coupon-order/${transactionId}/razorpay-order`, data);
        return res?.data;
    }

}

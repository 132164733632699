import React, { useEffect, useState } from 'react'
import ProgressClock from "../assets/pending_large.png"
import AvatarCard from '../components/AvatarCard'
import ItemList from '../components/ItemList'
import PaymentStatusDetailed from '../components/payments/PaymentStatusDetailed/PaymentStatusDetailed'
import { TransactionV3 } from '../interfaces/transactionV3'
import PromoService from '../services/promoService'
import SubscriptionService from '../services/subscriptionService'
import { retrieveFromSessionStorage } from '../utils/utils'
import moment from 'moment'
import {RequestedForEnum} from "../enums/RequestedForEnum";
import TrialService from "../services/trialService";

export default function PaymentPostProgress() {
    const [transaction, setTransaction] = useState<TransactionV3>()

    useEffect(() => {
        const fn = async () => {
            const transactionId = retrieveFromSessionStorage("transactionId")
            const requestedFor = retrieveFromSessionStorage("requestedFor")
            if (transactionId) {
                if(requestedFor === "promo") {
                    const promoService = new PromoService();
                    const txn = await promoService.fetchPaymentStatus(parseInt(transactionId))
                    setTransaction(txn)
                } else if (requestedFor === RequestedForEnum.TRIAL) {
                    const trialService = new TrialService();
                    const txn = await trialService.fetchPaymentStatus(parseInt(transactionId))
                    setTransaction(txn)
                } else{
                    const subscriptionService = new SubscriptionService();
                    const txn = await subscriptionService.fetchPaymentStatus(parseInt(transactionId))
                    setTransaction(txn)
                }
            }
        }

        fn()

    }, [])
    const renderList = () => {
        return (
            <div className='d-flex-column-center' style={{ alignItems: 'flex-start', gap: 20 }}>
                <div> <p>Status</p>  </div>
                <AvatarCard
                    title={"The payment is in progress. The server is facing some problem. We will notify you after the transaction Is complete"}
                />

                <ItemList
                    title={"Order Id"}
                    subtitle={transaction?.orderId || 'N/A'}
                />
            </div>
        )
    }

    return (
        <PaymentStatusDetailed
            title={"Payment In Progress"}
            subTitle={moment(transaction?.createdAt).format('Do MMM YYYY, h:mm a')}
            list={renderList}
            src={ProgressClock}
            bgColor={"#EEFBF0"}
        />
    )
}

import React, { useEffect, useState } from 'react'
import Check from "../assets/check_large_green.png"
import AvatarCard from '../components/AvatarCard'
import ItemList from '../components/ItemList'
import PaymentStatusDetailed from '../components/payments/PaymentStatusDetailed/PaymentStatusDetailed'
import PromoService from '../services/promoService'
import SubscriptionService from '../services/subscriptionService'
import { retrieveFromSessionStorage } from '../utils/utils'
import moment from 'moment'
import { useAppSelector } from '../store/hooks'
import {RequestedForEnum} from "../enums/RequestedForEnum";
import TrialService from "../services/trialService";
import {useNavigate} from "react-router-dom";
import OmsService from "../services/omsService";
import StarCleanerService from "../services/starCleanerService";
import {FirebaseCleverPurchaseEvent, FirebaseCleverTabRecordEvent} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {EventProperties} from "../firebase/EventProperties";
import {ScreenNames} from "../firebase/ScreenNames";
import DiscountCouponService from '../services/discountCouponService'
import PlatformLeadService from '../services/platformLeadService'
import MarketPlaceService from '../services/marketplaceService'
import ChefForPartyService from '../services/ChefForPartyService'

const promoService = new PromoService();
const trialService = new TrialService();
const subscriptionService = new SubscriptionService();
const omsService = new OmsService();
const starCleanerService = new StarCleanerService();
const discountCouponService = new DiscountCouponService()
const platformService = new PlatformLeadService()
const marketPlaceService = new MarketPlaceService();
const chefForPartyService = new ChefForPartyService()

export default function PaymentPostSuccess() {
    const navigate = useNavigate()
    const [transaction, setTransaction] = useState<any>()
    const transactionResponse = useAppSelector((state) => state?.qrCookPayment?.value?.userToCookPayment)
    const [amount, setAmount] = useState<number>(0);

    useEffect(() => {
        const fn = async () => {
            const transactionId = retrieveFromSessionStorage("transactionId")
            const omsId = retrieveFromSessionStorage("omsId")
            const starCleanerId = retrieveFromSessionStorage("starCleanerId");
            const requestedFor = retrieveFromSessionStorage("requestedFor")
            FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                [EventProperties.PAGE_NAME]: ScreenNames.POST_SUCCESS,
            });
            if(transactionResponse){
                setTransaction((transactionResponse as any)?.razorpayOrder)
                setAmount((transactionResponse as any)?.amount);
                FirebaseCleverPurchaseEvent((transactionResponse as any)?.razorpayOrder);
            }
            else if (transactionId) {
                if(requestedFor === RequestedForEnum.PROMO) {
                    const txn = await promoService.fetchPaymentStatus(parseInt(transactionId))
                    setAmount(txn.amount)
                    setTransaction(txn)
                } else if (requestedFor === RequestedForEnum.TRIAL) {
                    const txn = await trialService.fetchPaymentStatus(parseInt(transactionId))
                    let finalAmount = Number(txn.trialAmount) - Number(txn.discount);
                    setAmount(finalAmount < 0 ? 0 : finalAmount)
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.SUBSCRIPTION){
                    const txn = await subscriptionService.getFullBundleDetails(parseInt(transactionId))
                    setAmount(Number(txn?.razorpayOrder?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.OMS){
                    const txn = await omsService.getOmsDetails(Number(omsId))
                    setAmount(Number(txn?.razorpayOrder?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.STARCLEANER){
                    const txn = await starCleanerService.getStarCleanerDetails(Number(starCleanerId))
                    setAmount(Number(txn?.razorpayOrder?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.DISCOUNT_COUPON){
                    const txn = await discountCouponService.getDiscountCouponDetails(Number(transactionId))
                    setAmount(Number(txn?.razorpayOrder?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.PLATFORM_LEAD){
                    const txn = await platformService.getPlatformLeadDetails(Number(transactionId))
                    setAmount(Number(txn?.razorpayOrder?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                } else if (requestedFor === RequestedForEnum.CHEF_FOR_PARTY){
                    const service = await chefForPartyService.getChefForPartyDetails(Number(transactionId))
                    let orders =  service?.orders?.filter((e:any) => e.id === Number(retrieveFromSessionStorage("partyOrderId")));
                    setAmount(Number(orders[0]?.payment?.razorpayOrder?.amount))
                    FirebaseCleverPurchaseEvent(orders[0]?.payment?.razorpayOrder);
                    setTransaction(orders[0]?.payment?.orderPayment)
                }
                else if (requestedFor === RequestedForEnum.MARKET_PLACE) {
                    const txn = await marketPlaceService.getMarketPlaceOrderDetails(parseInt(transactionId))
                    setAmount(Number(txn?.amount))
                    setTransaction(txn)
                    FirebaseCleverPurchaseEvent(txn.razorpayOrder);
                }
            } else {
                navigate("/expired", { replace: true })
            }
        }

        fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const renderList = () => {
        return (
            <div className='d-flex-column-center' style={{ alignItems: 'flex-start', gap: 20 }}>
                <div> <p> Paid to  </p> </div>
                <AvatarCard
                    title={"Chefkart Hospitality PVT LTD"}
                    secondary={() => `₹ ${amount}`}
                />

                <ItemList
                    title={"Order Id"}
                    subtitle={transaction?.orderId || transaction?.razorpayOrderReferenceId || transaction?.razorpayOrderId || 'N/A'}
                />
            </div>
        )
    }

    return (
        <PaymentStatusDetailed
            title={"Payment Successful"}
            subTitle={moment(transaction?.createdAt).format('Do MMM YYYY, h:mm a')}
            list={renderList}
            src={Check}
            bgColor={"#EEFBF0"}
        />
    )
}

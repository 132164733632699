import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaymentStatus from '../components/payments/PaymentStatus'
import { delay } from '../utils/utils'
import {FirebaseCleverTabRecordEvent} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {EventProperties} from "../firebase/EventProperties";
import {ScreenNames} from "../firebase/ScreenNames";

export default function PaymentSuccessfull() {

  const navigate = useNavigate()

  useEffect(() => {
    const fn = async () => {
      FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
        [EventProperties.PAGE_NAME]: ScreenNames.PAYMENT_SUCCESS,
      });
      await delay(3000)
      navigate("/post-success")
    }

    fn()
  })
  return (
    <PaymentStatus
      heading='Payment Successful'
      src='https://assets3.lottiefiles.com/packages/lf20_wkebwzpz.json'
    />
  )
}

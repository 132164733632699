import Customer from "../interfaces/customer";
import { PromoTransactionResponse } from "../interfaces/promoTransactionResponse";
import { TransactionV3 } from "../interfaces/transactionV3";
import SubscriptionService from "../services/subscriptionService";
import { RazorpayConstants } from "../utils/constants";

interface PaymentInfoInterface {
    id: number;
    amount: number;
    orderId: string;
    email: string;
    fname: string;
    mobile: string;
    childOrderId?: number
}

export default class Razorpay {
    private readonly subscriptionService = new SubscriptionService()
    private init(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    private onFailedPayment = async (transactionId: number, errorObj: any) => {
    }

    /**
     *
     * @param subscription the transaction object returned by calling initiate payment route
     * @deprecated
     * @returns
     */
    async initiatePaymentFlow(subscription: TransactionV3 | PromoTransactionResponse, customer: Customer, onSuccessfulPayment: any) {
        await this.init('https://checkout.razorpay.com/v1/checkout.js');

        if(!subscription.amount || !subscription.orderId){
            window.location.replace("/expired")
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY as string,
            amount: subscription.amount * 100,
            order_id: subscription.orderId,
            description: RazorpayConstants.Description,
            currency: RazorpayConstants.Currency,
            name: RazorpayConstants.CompanyName,
            handler: (paymentObj: any) => onSuccessfulPayment(subscription.id, paymentObj),
            prefill: {
                email: customer.email || 'void@razorpay.com',
                name: customer.fname || '',
                contact: customer.mobile || ''
            },
            theme: RazorpayConstants.Theme
        }

        const paymentObject = new (window as any).Razorpay(options)
        await paymentObject.open(options)
    }

    async initiateRazorPayPaymentFlow(paymentInfo: PaymentInfoInterface, onSuccessfulPayment: any) {
        await this.init('https://checkout.razorpay.com/v1/checkout.js');

        if(!paymentInfo.amount || !paymentInfo.orderId){
            window.location.replace("/expired")
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY as string,
            amount: paymentInfo.amount * 100,
            order_id: paymentInfo.orderId,
            description: RazorpayConstants.Description,
            currency: RazorpayConstants.Currency,
            name: RazorpayConstants.CompanyName,
            handler: (paymentObj: any) => onSuccessfulPayment(paymentInfo.id, paymentObj),
            prefill: {
                email: paymentInfo.email || 'void@razorpay.com',
                name: paymentInfo.fname || '',
                contact: paymentInfo.mobile || ''
            },
            theme: RazorpayConstants.Theme,
            config: {
                display: {
                    hide: [
                        {
                            method: 'emi',
                        },
                    ],
                    preferences: {
                        show_default_blocks: true,
                    },
                },
            },
        }

        const paymentObject = new (window as any).Razorpay(options)
        await paymentObject.open(options)
    }
    async initiateRazorPayPaymentFlowForMultipleOrder(paymentInfo: PaymentInfoInterface, onSuccessfulPayment: any) {
        await this.init('https://checkout.razorpay.com/v1/checkout.js');

        if(!paymentInfo.amount || !paymentInfo.orderId){
            window.location.replace("/expired")
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY as string,
            amount: paymentInfo.amount * 100,
            order_id: paymentInfo.orderId,
            description: RazorpayConstants.Description,
            currency: RazorpayConstants.Currency,
            name: RazorpayConstants.CompanyName,
            handler: (paymentObj: any) => onSuccessfulPayment(paymentInfo.id, paymentInfo.childOrderId, paymentObj),
            prefill: {
                email: paymentInfo.email || 'void@razorpay.com',
                name: paymentInfo.fname || '',
                contact: paymentInfo.mobile || ''
            },
            theme: RazorpayConstants.Theme
        }

        const paymentObject = new (window as any).Razorpay(options)
        await paymentObject.open(options)
    }
}

import React from 'react';

interface InputProps {
    title: any;
    subtitle?: string;
    actionText: string;
    action?: () => void;
}

export default function ClickableCardItem(props: InputProps) {
    return (
        <div className='d-flex-center' style={{ width: '100%', justifyContent: 'space-between', cursor: 'pointer'}} onClick={props.action}>
            <div>
                <p style={{fontSize: '15px', margin: 0, fontWeight: 500}}> {props.title} </p>
                <p style={{fontSize: '12px', margin: 0, marginTop: 8}}> {props.subtitle} </p>
            </div>
            <div style={{color: '#FF941A'}}>
                <p style={{fontSize: '15px', margin: 0, fontWeight: 500}}>{props.actionText} </p>
            </div>
        </div>
    )
}

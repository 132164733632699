import React  from 'react';

interface InputProps {
    title: string;
}

export default function TopBar(props: InputProps) {
    const {title} = props;
    return (
        <div style={{ width: '100%', boxShadow: '0px 4px 4px #E6E6E767', }}>
            <p style={{
                fontWeight: 500,
                fontSize: 20,
                paddingLeft: 16,
            }}>{title}</p>
        </div>
    )
}

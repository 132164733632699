import {useCallback, useEffect, useMemo, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ItemList from '../components/ItemList'
import { EventBusEventsEnum } from '../enums/EventBusEventsEnum'
import { ProductTypes } from '../enums/ProductTypesEnum'
import EventBus from '../EventBus'
import Razorpay from '../integrations/Razorpay'
import Coupon from '../interfaces/coupon'
import UserBundleMapping, {MealMappingInterface} from '../interfaces/userBundleMapping'
import CustomerService from '../services/customerService'
import SubscriptionService from '../services/subscriptionService'
import { calculateNetPrice, getMealString, retrieveFromSessionStorage, saveToSessionStorage } from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import ClickableCardItem from "../components/common/ClickableCardItem";
import {SlotsEnum} from "../enums/SlotsEnum";
import {RequestedForEnum} from "../enums/RequestedForEnum";
import RazorpayPayment from "../interfaces/razorpayPayment";
import {RazorpayTransactionStatesEnum} from "../enums/RazorpayTransactionStatesEnum";
import { PaymentStatusEnum } from '../enums/PaymentStatusEnum'
import {FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {ScreenNames} from "../firebase/ScreenNames";
import {EventProperties} from "../firebase/EventProperties";

const customerService = new CustomerService()

export default function BundlePayment() {

    const [subscriptionDetails, setSubscriptionDetails] = useState<UserBundleMapping>()
    const [selectedCoupon, setSelectedCoupon] = useState<Coupon>()
    const [netPrice, setNetPrice] = useState({
        amount: "0.00",
        discount: "0.00"
    })
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const subscriptionService = useMemo(() => new SubscriptionService(), [])

    const onCouponApply = useCallback((
        async (couponCode: string, subscriptionDetails: UserBundleMapping) => {
            const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId")
            const coupon = await subscriptionService.verifyCoupon({
                userBundleId: Number(userBundleMappingId)!,
                couponCode: couponCode,
                productType: ProductTypes.SUBSCRIPTION,
            });
            setSelectedCoupon(coupon);
            const netPrice = calculateNetPrice(subscriptionDetails!.quotedNetPrice, coupon);
            setNetPrice(netPrice);
        }
    ),[subscriptionService])

    useEffect(() => {
        const fn = async () => {
            setLoading(true);
            const token = searchParams.get("token");
            const userBundleMappingId = searchParams.get("userBundleMappingId");
            const couponCode = searchParams.get("couponCode");

            if (token && userBundleMappingId) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("userBundleMappingId", userBundleMappingId)
                    saveToSessionStorage("requestedFor", RequestedForEnum.SUBSCRIPTION);
                    FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                        [EventProperties.PAGE_NAME]: ScreenNames.SUBSCRIPTION_PAYMENT,
                    });

                    const userBundleMapping = await subscriptionService.getFullBundleDetails(Number(userBundleMappingId));

                    if (![PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS].includes(userBundleMapping?.paymentStatus)) {
                        navigate("/expired", { replace: true })
                    }
                    setSubscriptionDetails(userBundleMapping)
                    if(couponCode && !userBundleMapping?.razorpayOrderId){
                        onCouponApply(couponCode, userBundleMapping);
                    }
                } catch (err) {
                    navigate("/expired")
                }
                setLoading(false);
            }
        }

        fn()
    }, [navigate, onCouponApply, searchParams, subscriptionService])

    const RenderDiscountedPrice = () => {
        if (!selectedCoupon?.couponCode) {
            return <span style={{ margin: 0 }}>Rs. {subscriptionDetails?.razorpayOrderId ? subscriptionDetails?.razorpayOrder?.amount : subscriptionDetails?.quotedNetPrice}</span>
        }

        return (
            <span style={{margin: 0, marginBottom: 2}}>
                <span style={{ color: '#FF941A' }} >
                    {`Rs ${parseInt(netPrice.amount) < 0 ? "0.00" : netPrice.amount} `}
                </span>
                <span
                    style={{
                        textDecoration: 'line-through',
                        marginRight: '5px',
                        fontStyle: 'italic',
                        fontSize: 12
                    }}
                >{`Rs ${subscriptionDetails?.quotedNetPrice}`}
                </span>
            </span>
        )
    }

    const onSuccessfulPayment = async (transactionId: number, paymentObj: any) => {
        const razorpayPayment: RazorpayPayment = {
            razorpayOrderId: paymentObj.razorpay_order_id,
            razorpayPaymentId: paymentObj.razorpay_payment_id,
            razorpaySignature: paymentObj.razorpay_signature,
            state: RazorpayTransactionStatesEnum.COMPLETED
        }
        await subscriptionService.updatePaymentStatus(transactionId, razorpayPayment)
        window.location.replace(`/connecting-securely`)
    }

    const onClickPay = async () => {
        if (!subscriptionDetails) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        const transactionV3Obj = await subscriptionService.initiatePayment(subscriptionDetails!.id, selectedCoupon?.id)
        if (!transactionV3Obj) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        saveToSessionStorage("transactionId", transactionV3Obj!.id);
        saveToSessionStorage("requestedFor", RequestedForEnum.SUBSCRIPTION);
        if (transactionV3Obj.paymentStatus === PaymentStatusEnum.PAID) {
            navigate("/success", { replace: true })
            return;
        }
        const rzpy = new Razorpay();
        const customer = await customerService.getCustomerDetails()
        customer && FirebaseCleverTabSetProfile(customer)
        FirebaseCleverTabRecordEvent(EventNames.OKANE_PAY_NOW_CLICKED, {
            [EventProperties.REFERENCE_MODEL_NAME]: transactionV3Obj?.razorpayOrder?.referenceModelName,
            [EventProperties.AMOUNT]: transactionV3Obj?.razorpayOrder?.amount,
        });
        await rzpy.initiateRazorPayPaymentFlow({
            id: transactionV3Obj?.id,
            amount: Number(transactionV3Obj?.razorpayOrder?.amount),
            orderId: transactionV3Obj.razorpayOrder?.razorpayOrderId,
            email: customer?.email || '',
            fname: customer?.fname || '',
            mobile: customer?.mobile || '',
        }, onSuccessfulPayment);
    }

    // @ts-ignore
    return (
        <div
            className='d-flex-column-center'
            style={{
                width: '100%',
                height: '100%',
                maxWidth: '500px',
                justifyContent: 'flex-start',
                gap: 20,
            }}>
            <div className='d-flex-column-center' style={{ width: '100%', gap: 20}}>
            <TopBar title={'Summary'}/>
            <div
                className='d-flex-column-center'
                style={{
                    width: '100%',
                    height: '100%',
                    gap: 20,
                    justifyContent: 'flex-start',
                }}>
                <CardTitle title={'Subscription Details'}/>
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '95%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 10
                    }}>
                    <div className="card">
                        <ItemList
                            title='Subscription Amount'
                            action={() => `Rs ${subscriptionDetails?.quotedNetPrice || '...'}`}
                        />
                        <div style={{ borderTop: 'dashed', borderWidth: 0.5, color: '#BDBDBD'  }} />

                        <ItemList
                            title='Total Members'
                            action={() => subscriptionDetails?.userBundleMeta?.totalPeople}
                        />

                        {subscriptionDetails?.mealMapping ? subscriptionDetails?.mealMapping?.map((item: MealMappingInterface) => {
                            return (
                                <ItemList
                                    key={item.slot}
                                    title={getMealString(item.slot)}
                                    action={() => `${item.time}`}
                                />
                            )
                        }) : subscriptionDetails?.userBundleMeta?.mealMapping?.map((item: SlotsEnum) => {
                            return (
                                <ItemList
                                    key={item}
                                    title={getMealString(item)}
                                />
                            )
                        })}

                    </div>
                </div>
                {loading ? '' : !subscriptionDetails?.razorpayOrderId && (
                    <CardTitle title={'Offers & Benefits'}/>
                )}
                {loading ? '' : !subscriptionDetails?.razorpayOrderId && (
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '95%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 10
                    }}>
                    <div className="card">
                        {
                            selectedCoupon ?
                                <ClickableCardItem
                                    title={selectedCoupon?.couponCode || '---'}
                                    subtitle={`Rs ${netPrice?.discount} discount added`}
                                    action={() => {
                                        setSelectedCoupon(undefined);
                                        setNetPrice({
                                            amount: "0.00",
                                            discount: "0.00"
                                        });
                                        const token = retrieveFromSessionStorage("token")
                                        const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId");
                                        navigate(`/pay?token=${token}&userBundleMappingId=${userBundleMappingId}`);
                                    }}
                                    actionText={'Remove'}
                                /> :
                                <ClickableCardItem
                                    title={'Apply Coupons'}
                                    subtitle={'Enjoy benefits with our exclusive offers'}
                                    action={() => {
                                        const token = retrieveFromSessionStorage("token")
                                        const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId");
                                        navigate(`/coupon?token=${token}&userBundleMappingId=${userBundleMappingId}`);
                                    }}
                                    actionText={'❯'}
                                />
                        }
                    </div>
                </div>
                )}
            </div>
            </div>
            <div style={{ width: '100%'}}>
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '95%',
                        padding: '10px'
                    }}>
                    <ItemList
                        title={<RenderDiscountedPrice />}
                        subtitle={'Net payable amount'}
                        action={
                            () => <button
                                style={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    borderRadius: '9px',
                                    padding: '12px 22px',
                                    cursor: 'pointer',
                                    border: 'none'
                                }}
                                onClick={onClickPay}
                            >
                                Pay Now
                            </button>}
                    />
                </div>
            </div>
        </div>
    )
}

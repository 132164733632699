import HttpClient from "../HttpClient";
import Coupon from "../interfaces/coupon";
export default class CommonService {
    
    async fetchAllActiveCouponsByHouseId(houseId: number, productType: any,date:string): Promise<Coupon[]> {
        const data = {
            houseId,
            date
        }
        const res = await HttpClient.get(`customers/v5/coupon/${productType}`,{
            params: data
        });
        return res?.data;
    }
}
import React, { EventHandler } from 'react';

interface InputProps {
    name: string;
    description: string;
    termsDescription: string;
    onActionClick: EventHandler<any>
}

export default function PlanCard(props: InputProps) {
    return (
        <div
            style={{
                boxShadow: '0px 1px 4px #D6D6D69E',
                padding: 20,
                width: '80%',
                borderRadius: '9px',
                gap: 16,
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
            }}
            onClick={props.onActionClick}
        >
            <div
                className='d-flex-center'
                style={{ justifyContent: 'space-between' }}>
                <div >
                    <p style={{ fontSize: '14px', fontWeight: 400, margin: 0, marginBottom: 6, }}>{props.name}</p>
                    <p style={{ fontStyle: 'italic', fontSize: '12px', color: '#51C452', margin: 0 }}>
                        {props.description}
                    </p>
                </div>
                <p
                    style={{
                        color: '#FF941A',
                        fontSize: '12px',
                        cursor: 'pointer',
                        border: 'none',
                        margin: 0,
                        alignItems: 'flex-start',
                    }}>
                    SELECT
                </p>
            </div>
            <div style={{ borderTop: 'dashed', borderWidth: 0.5, color: '#BDBDBD'  }} />
            <div className='d-flex-center' style={{ justifyContent: 'space-between' }}>
                <p
                    style={{
                        maxWidth: '70%',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        color: '#8A8A8A',
                        margin: 0,
                    }}>
                    {props.termsDescription}
                </p>
            </div>
        </div>
    )
}

import React from 'react'
import Cross from "../assets/red_cross_large.png"
import PaymentStatus from '../components/payments/PaymentStatus'

export default function PaymentExpired() {
    return (
        <PaymentStatus
          heading='Payment Link Expired'
          icon={Cross}
        />
      )
}
import HttpClient from "../HttpClient";
import RazorpayPayment from "../interfaces/razorpayPayment";

export default class ChefForPartyService {

    async getChefForPartyDetails(chefForPartyId: number){
        const res = await HttpClient.get(`/customer/chef-for-party/${chefForPartyId}`);
        if (res?.status === 200){
            return res?.data;
        }
        return null;
    }

    async initiatePayment(partyId: number, partyOrderId: number, payload: any): Promise<any> {
        const res = await HttpClient.post(`/customer/chef-for-party/${partyId}/order/${partyOrderId}/create-razorpay-order`, payload);
        return res?.data;
    }

    async updatePaymentStatus(partyId: number, partyOrderId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/customer/chef-for-party/${partyId}/order/${partyOrderId}/update-razorpay-order`, data);
        return res?.data;
    }

}

import {createSlice} from "@reduxjs/toolkit";

export const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState: {
        value: {
            customer: null,
            authDetails: null,
        }
    },
    reducers: {
        setAuthDetails: (state, action) => {
            state.value.authDetails = action.payload;
        },
        setCustomerDetails: (state, action) => {
            state.value.customer = action.payload;
        },
    }
})

export const {setAuthDetails, setCustomerDetails } = customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;

import HttpClient from "../HttpClient";
import { PromoTransactionResponse } from "../interfaces/promoTransactionResponse";
import RazorpayPayment from "../interfaces/razorpayPayment";
import { TransactionV3 } from "../interfaces/transactionV3";

export default class PromoService {
    async initiatePayment(customerName: string, customerPhone: string, address: string, amount: number, sku: string): Promise<PromoTransactionResponse> {
        let body = {
            customerName,
            customerPhone,
            address,
            amount,
            sku
        }


        const res = await HttpClient.post(`/promo/product/pay`, body);
        return res?.data;
    }

    async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/promo/product/razorpay-transactions/${transactionId}`, data);
        return res?.data;
    }

    async fetchPaymentStatus(transactionId: number): Promise<TransactionV3> {
        const res = await HttpClient.get(`/promo/product/razorpay-transactions/${transactionId}`);
        return res?.data;
    }
}

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardTitle from "../components/common/CardTitle";
import TopBar from "../components/common/TopBar";
import ItemList from "../components/ItemList";
import { RequestedForEnum } from "../enums/RequestedForEnum";
import { FirebaseCleverTabRecordEvent } from "../firebase";
import { EventNames } from "../firebase/EventNames";
import { EventProperties } from "../firebase/EventProperties";
import { ScreenNames } from "../firebase/ScreenNames";
import Razorpay from "../integrations/Razorpay";
import { MarketPlaceRazorpayOrder } from "../interfaces/MarketPlaceRazorpayOrder";
import MarketPlaceService from "../services/marketplaceService";
import {
  retrieveFromSessionStorage,
  saveToSessionStorage,
} from "../utils/utils";
import RazorpayPayment from "../interfaces/razorpayPayment";
import { RazorpayTransactionStatesEnum } from "../enums/RazorpayTransactionStatesEnum";

const marketplaceService = new MarketPlaceService();
const razorpayOrder = new Razorpay();

const MarketPlacePayment = () => {
  const [counter, setCounter] = useState<number>(1);
  const [price, setPrice] = useState<number | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const marketPlaceOrderId = searchParams.get("serviceId");

  const onSuccessfulPayment = async (transactionId: number, paymentObj: any) => {
    const razorpayPayment: RazorpayPayment = {
      razorpayOrderId: paymentObj.razorpay_order_id,
      razorpayPaymentId: paymentObj.razorpay_payment_id,
      razorpaySignature: paymentObj.razorpay_signature,
      state: RazorpayTransactionStatesEnum.COMPLETED
    }
    await marketplaceService.updatePaymentStatus(transactionId, razorpayPayment)
    window.location.replace(
      `/connecting-securely?requestedFor=${RequestedForEnum.MARKET_PLACE}`
    );
  };

  useEffect(() => {
    marketplaceService.getMarketPlaceCookPrice().then((res) => {
      setPrice(res.price);
    });

    if (token && marketPlaceOrderId) {
      try {
        saveToSessionStorage("token", token);
        saveToSessionStorage("marketPlaceOrderId", marketPlaceOrderId);
        saveToSessionStorage("requestedFor", RequestedForEnum.MARKET_PLACE);
        FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
          [EventProperties.PAGE_NAME]: ScreenNames.MARKET_PLACE_PAYMENT,
        });
      } catch (err) {
        navigate("/expired", { replace: true });
      }
    }
  }, [marketPlaceOrderId, navigate, token]);

  const Price = () => {
    return (
      <p>
        <span>{price}.00</span>
        <span
          style={{ fontSize: "12px", color: "#8A8A8A", marginLeft: "0.1rem" }}
        >
          (Per Cook)
        </span>
      </p>
    );
  };

  const TokenCounter = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <button
            className="counter-button"
            onClick={() => {
              setCounter((counter) => {
                if (counter > 1) {
                  return counter - 1;
                }
                return counter;
              });
            }}
          >
            -
          </button>
        </div>
        <div style={{ margin: "0rem 0.7rem" }}>{counter}</div>
        <div>
          <button
            className="counter-button"
            onClick={() => {
              setCounter(counter + 1);
            }}
          >
            +
          </button>
        </div>
      </div>
    );
  };

  if (price === null) return null;

  return (
    <div
      className="d-flex-column-center"
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "500px",
        justifyContent: "flex-start",
        gap: 20,
      }}
    >
      <div className="d-flex-column-center" style={{ width: "100%", gap: 20 }}>
        <TopBar title={"Summary"} />
        <div
          className="d-flex-column-center"
          style={{
            width: "100%",
            height: "100%",
            gap: 20,
            justifyContent: "flex-start",
          }}
        >
          <CardTitle title={"Details"} />
          <div
            className="d-flex-column-center"
            style={{
              width: "95%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 10,
            }}
          >
            <div className="card">
              <ItemList title={"Price"} action={() => <Price />} />
              <div
                style={{
                  borderTop: "dashed",
                  borderWidth: 0.5,
                  color: "#BDBDBD",
                }}
              />

              <ItemList
                title="Number of Cooks"
                action={() => <TokenCounter />}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          className="d-flex-column-center"
          style={{
            width: "95%",
            padding: "10px",
          }}
        >
          <ItemList
            title={`Rs. ${counter * price}.00`}
            subtitle={"Net payable amount (Non Refundable)"}
            action={() => (
              <button
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "9px",
                  padding: "12px 22px",
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() => {
                  marketplaceService
                    .createMarketPlaceOrder(
                      Number(retrieveFromSessionStorage("marketPlaceOrderId")),
                      {
                        noOfCooks: counter,
                      }
                    )
                    .then((res) => {
                      return res;
                    })
                    .then((res: MarketPlaceRazorpayOrder) => {
                      saveToSessionStorage("transactionId", res.id);
                      razorpayOrder.initiateRazorPayPaymentFlow(
                        {
                          id: res.id,
                          amount: res.amount,
                          orderId: String(res.razorpayOrderId),
                          email: "",
                          fname: "",
                          mobile: res?.customerPhone || "",
                        },
                        onSuccessfulPayment
                      );
                    })
                    .catch((err) => {
                      navigate("/failed");
                    });
                }}
              >
                Pay Now
              </button>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketPlacePayment;

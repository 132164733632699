import { setActiveUserCookMapping, setAllUserCookMapping, setUserToCookPayment } from "../../reducers/qrCookPayment";
import store from "../../store"

export const SetActiveUserCookMappingAction = (data: any) => {
    store.dispatch(setActiveUserCookMapping(data))
}
export const SetAllUserCookMappingAction = (data: any) => {
    store.dispatch(setAllUserCookMapping(data))
}
export const SetUserToCookPaymentAction = (data: any) => {
    store.dispatch(setUserToCookPayment(data))
}

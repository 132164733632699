import React from 'react';
import DownloadAppCard from '../../DownloadAppCard';

interface InputProps {
    src?: string;
    title?: string;
    subTitle?: string;
    list?: any;
    bgColor?: string;
}

export default function PaymentStatusDetailed(props: InputProps) {
    return (
        <div
            className='d-flex-column-center'
            style={{
                height: '100%',
                width: '100%',
                maxWidth: "500px",
                position: 'relative'
            }}
        >
            <div
                className='d-flex-column-center'
                style={{
                    width: '100%',
                    height: '50%',
                    padding: '20px',
                    backgroundColor: props.bgColor,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 5
                }}
            >
                <img style={{ maxWidth: '100px', width: '50%' }} src={props.src} alt={"status-logo"} />
                <p style={{ fontSize: '27px', margin: 0 }}> {props.title} </p>
                <p style={{ fontSize: '15px', margin: 0 }}> {props.subTitle} </p>
            </div>

            <div
                className='d-flex-column-center'
                style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    padding: '20px',
                    flexGrow: 1
                }}>
                <DownloadAppCard />
            </div>

            <div style={{ width: "90%", position: "absolute" }}>
                <div
                    style={{
                        padding: 20,
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 4px #D6D6D69E',
                        borderRadius: '10px'
                    }}>
                    <props.list />
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CustomSnackbar from "../components/CustomSnackbar";
import BundlePayment from './BundlePayment';
import ChooseBundle from './ChooseBundle';
import PaymentExpired from './PaymentExpired';
import PaymentFailed from './PaymentFailed';
import PaymentPending from './PaymentPending';
import PaymentPostProgress from './PaymentPostProgress';
import PaymentPostSuccess from './PaymentPostSuccess';
import PaymentProgress from './PaymentProgress';
import PaymentSuccessfull from './PaymentSuccessful';
import PromoPage from './PromoPage';
import ChooseCoupon from "./ChooseCoupon";
import CookPayment from './CookPayment/CookPayment';
import TrialPayment from "./TrialPayment";
import TrialCoupons from "./TrialCoupons";
import OmsPayment from "./OmsPayment";
import StarCleanerPayment from "./StarCleanerPayment";
import DiscountCouponOrderPayment from './DiscountCouponOrderPayment';
import PlatformLeadPayment from './PlatformLeadPayment';
import MarketPlacePayment from './MarketPlacePayment';
import ChefForPartyPayment from './ChefForPartyPayment';

export default function App() {

    // working example of eventbus
    // useEffect(() => {
    //     EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
    //         message: 'working'
    //     })
    // }, [])

    return (
        <div
            className='d-flex-column-center'
            style={{
                height: '100vh',
                width: '100vw',
                overflow: 'hidden',
            }}
        >
            <Routes>
                <Route path='/pay' element={<BundlePayment/>}/>
                <Route path='/trial-pay' element={<TrialPayment />}/>
                <Route path='/star-cleaner-pay' element={<StarCleanerPayment />}/>
                <Route path='/oms-pay' element={<OmsPayment />}/>
                <Route path='/discount-coupon-pay' element={<DiscountCouponOrderPayment />}/>
                <Route path='/platform-lead-pay' element={<PlatformLeadPayment />}/>
                <Route path='/chef-For-Party-pay' element={<ChefForPartyPayment />}/>
                <Route path='/renew' element={<ChooseBundle/>}/>
                <Route path='/coupon' element={<ChooseCoupon/>}/>
                <Route path='/trial-coupon' element={<TrialCoupons/>}/>
                <Route path='/promo' element={<PromoPage/>}/>
                {/* <Route path='/trial' element={<PaymentDetails/>}/> */}
                <Route path='/expired' element={<PaymentExpired/>}/>
                <Route path='/cook-payment/:cookId' element={<CookPayment/>}/>
                <Route path='/success' element={<PaymentSuccessfull/>}/>
                <Route path='/failed' element={<PaymentFailed/>}/>
                <Route path='/connecting-securely' element={<PaymentPending/>}/>
                <Route path='/post-success' element={<PaymentPostSuccess/>}/>
                <Route path='/progress' element={<PaymentProgress/>}/>
                <Route path='/progress-details' element={<PaymentPostProgress />} />
                <Route path='/market-place-order-pay' element={<MarketPlacePayment />} />
            </Routes>
            <CustomSnackbar/>
        </div>
    )
}

//TODO: route protection

import React, { useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ItemList from '../components/ItemList'
import { EventBusEventsEnum } from '../enums/EventBusEventsEnum'
import EventBus from '../EventBus'
import Razorpay from '../integrations/Razorpay'
import CustomerService from '../services/customerService'
import { retrieveFromSessionStorage, saveToSessionStorage } from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import {RequestedForEnum} from "../enums/RequestedForEnum";
import RazorpayPayment from "../interfaces/razorpayPayment";
import {RazorpayTransactionStatesEnum} from "../enums/RazorpayTransactionStatesEnum";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import {FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {ScreenNames} from "../firebase/ScreenNames";
import {EventProperties} from "../firebase/EventProperties";
import DiscountCouponService from '../services/discountCouponService'
import DiscountCouponOrder from '../interfaces/discountCouponOrder'
import Customer from '../interfaces/customer'

const customerService = new CustomerService()
const discountCouponService = new DiscountCouponService();


export default function DiscountCouponOrderPayment() {

    const [details, setDetails] = useState<DiscountCouponOrder>()
    const [user, setUser] = useState<Customer | null>()
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        const fn = async () => {
            const token = searchParams.get("token");
            const discountCouponId = searchParams.get("serviceId");

            if (token && discountCouponId) {
              saveToSessionStorage("token", token)
              saveToSessionStorage("discountCouponId", discountCouponId)
              saveToSessionStorage("requestedFor", RequestedForEnum.DISCOUNT_COUPON);
              FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                [EventProperties.PAGE_NAME]: ScreenNames.DISCOUNT_COUPON_PAYMENT,
              });
            }else{
              navigate("/expired")
            }
        }
      fn()
    }, [navigate, searchParams])

    useEffect(() => {
      setLoading(true);
      if (retrieveFromSessionStorage("token")) {
        (async function () {
          const customer = await customerService.getCustomerDetails()
          setUser(customer)
          const discountCouponData = await discountCouponService.getDiscountCouponDetails(Number(retrieveFromSessionStorage("discountCouponId")));
          if (![PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS].includes(discountCouponData?.paymentStatus)) {
              navigate("/expired", { replace: true })
          }
          setDetails(discountCouponData)
        })();
      } else {
        navigate("/expired");
      }
      setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const RenderDiscountedPrice = (props: any) => {
        return (
            <span style={{margin: 0, marginBottom: 2}}>
                <span style={{ color: '#FF941A' }} >
                    {`Rs ${props?.data?.amount} `}
                </span>
            </span>
        )
    }

    const onSuccessfulPayment = async (transactionId: number, paymentObj: any) => {
        const razorpayPayment: RazorpayPayment = {
            razorpayOrderId: paymentObj.razorpay_order_id,
            razorpayPaymentId: paymentObj.razorpay_payment_id,
            razorpaySignature: paymentObj.razorpay_signature,
            state: RazorpayTransactionStatesEnum.COMPLETED
        }
        await discountCouponService.updatePaymentStatus(transactionId, razorpayPayment)
        window.location.replace(`/connecting-securely`)
    }

    const onClickPay = async () => {
        if (!details) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        const transactionV3Obj = await discountCouponService.initiatePayment(details!.id)
        if (!transactionV3Obj) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        saveToSessionStorage("transactionId", transactionV3Obj!.id);
        saveToSessionStorage("requestedFor", RequestedForEnum.DISCOUNT_COUPON);
        if (transactionV3Obj.paymentStatus === PaymentStatusEnum.PAID) {
            navigate("/success", { replace: true })
            return;
        }
        const rzpy = new Razorpay();
        const customer = await customerService.getCustomerDetails()
        customer && FirebaseCleverTabSetProfile(customer)
        FirebaseCleverTabRecordEvent(EventNames.OKANE_PAY_NOW_CLICKED, {
            [EventProperties.REFERENCE_MODEL_NAME]: transactionV3Obj?.razorpayOrder?.referenceModelName,
            [EventProperties.AMOUNT]: transactionV3Obj?.razorpayOrder?.amount,
        });
        await rzpy.initiateRazorPayPaymentFlow({
            id: transactionV3Obj?.id,
            amount: Number(transactionV3Obj?.razorpayOrder?.amount),
            orderId: transactionV3Obj.razorpayOrder?.razorpayOrderId,
            email: customer?.email || '',
            fname: customer?.fname || '',
            mobile: customer?.mobile || '',
        }, onSuccessfulPayment);
    }

    // @ts-ignore
    return (
      <div
        className="d-flex-column-center"
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "500px",
          justifyContent: "flex-start",
          gap: 20,
        }}
      >
        <div
          className="d-flex-column-center"
          style={{ width: "100%", gap: 20 }}
        >
          <TopBar title={"Summary"} />
          <div
            className="d-flex-column-center"
            style={{
              width: "100%",
              height: "100%",
              gap: 20,
              justifyContent: "flex-start",
            }}
          >
            <CardTitle title={"Order Details"} />
            {!loading && (
              <div
                className="d-flex-column-center"
                style={{
                  width: "95%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 10,
                }}
              >
                <div className="card">
                  <ItemList
                    title="Total Amount"
                    action={() => `Rs ${details?.amount || "..."}`}
                  />
                  <div
                    style={{
                      borderTop: "dashed",
                      borderWidth: 0.5,
                      color: "#BDBDBD",
                    }}
                  />
                  <ItemList
                    title="Customer Name"
                    action={() => `${user?.fname + " " + user?.lname || "..."}`}
                  />
                  <ItemList
                    title="Customer Phone Number"
                    action={() => `${user?.mobile || "..."}`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="d-flex-column-center"
            style={{
              width: "95%",
              padding: "10px",
            }}
          >
            {!loading && (
              <ItemList
                title={<RenderDiscountedPrice data={details} />}
                subtitle={"Net payable amount"}
                action={() => (
                  <button
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "9px",
                      padding: "12px 22px",
                      cursor: "pointer",
                      border: "none",
                    }}
                    onClick={onClickPay}
                  >
                    Pay Now
                  </button>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
}

export enum RequestedForEnum {
  PROMO = "promo",
  TRIAL = "trial",
  SUBSCRIPTION = "subscription",
  OMS = "ONE_MEAL_SERVICE",
  STARCLEANER = "star-cleaner",
  DISCOUNT_COUPON = "discount-coupon",
  PLATFORM_LEAD = "platform-lead",
  MARKET_PLACE = "market-place-order",
  CHEF_FOR_PARTY = 'chef-for-party'
}

import { useState } from 'react'
import { EventBusEventsEnum } from "../enums/EventBusEventsEnum"
import EventBus from "../EventBus"
import Banner from '../assets/anveshan_banner.png'
import PromoBanner from "../components/promos/BannerComponent"
import PromoFormComponent from "../components/promos/formComponent"
import Razorpay from '../integrations/Razorpay'
import { saveToSessionStorage } from '../utils/utils'
import PromoService from '../services/promoService'
import Customer from "../interfaces/customer";
import RazorpayPayment from '../interfaces/razorpayPayment'
import { RazorpayTransactionStatesEnum } from '../enums/RazorpayTransactionStatesEnum'
import {RequestedForEnum} from "../enums/RequestedForEnum";

export default function PromoPage() {
    const promoService = new PromoService();
    const [name, setName] = useState<string>();
    const [mobile, setMobile] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [amount, setAmount] = useState<number>(849);
    const [sku, setSku] = useState<string>("Anveshan Ghee 500 Grams");

    const onSuccessfulPayment = async (transactionId: number, paymentObj: any) => {
        const razorpayPayment: RazorpayPayment = {
            razorpayOrderId: paymentObj.razorpay_order_id,
            razorpayPaymentId: paymentObj.razorpay_payment_id,
            razorpaySignature: paymentObj.razorpay_signature,
            state: RazorpayTransactionStatesEnum.COMPLETED
        }
        await promoService.updatePaymentStatus(transactionId, razorpayPayment)
        window.location.replace("/connecting-securely?requestedFor=promo")
    }

    const onSubmitCb = async () => {
        if(!(name && mobile && address && amount)){
            console.log(name, mobile, address, amount, sku);
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Please fill all the details"
            });
            return
        }

        const transactionV3Obj = await promoService.initiatePayment(name, mobile, address, amount, sku)
        if (!transactionV3Obj) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }

        const customer: Customer  = {
            id: 0,
            fname: name,
            mname: "",
            lname: "",
            mobile
        }

        saveToSessionStorage("transactionId", transactionV3Obj!.id);
        saveToSessionStorage("requestedFor", RequestedForEnum.PROMO)
        const rzpy = new Razorpay();
        await rzpy.initiatePaymentFlow(transactionV3Obj!, customer, onSuccessfulPayment);

    }

    return (
        <div
            className='d-flex-column-center promopage'
            style={{
                justifyContent: 'center',
                overflow:'hidden',
                padding: '20px'
            }}
        >
            <PromoBanner src={Banner}/>
            <PromoFormComponent
                setName={setName}
                setAddress={setAddress}
                setMobile={setMobile}
                setAmount={setAmount}
                setSku={setSku}
                onSubmitCb={onSubmitCb}
            />
        </div>
    )
}

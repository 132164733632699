import React, { useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ItemList from '../components/ItemList'
import { EventBusEventsEnum } from '../enums/EventBusEventsEnum'
import EventBus from '../EventBus'
import Razorpay from '../integrations/Razorpay'
import CustomerService from '../services/customerService'
import { retrieveFromSessionStorage, saveToSessionStorage } from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import {RequestedForEnum} from "../enums/RequestedForEnum";
import RazorpayPayment from "../interfaces/razorpayPayment";
import {RazorpayTransactionStatesEnum} from "../enums/RazorpayTransactionStatesEnum";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import {FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {ScreenNames} from "../firebase/ScreenNames";
import {EventProperties} from "../firebase/EventProperties";
import Customer from '../interfaces/customer'
import ChefForPartyService from '../services/ChefForPartyService'

const customerService = new CustomerService()
const chefForPartyService = new ChefForPartyService();


export default function ChefForPartyPayment() {

    const [details, setDetails] = useState<any>()
    const [order, setOrder] = useState<any>()
    const [user, setUser] = useState<Customer | null>()
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        const fn = async () => {
          const token = searchParams.get("token");
          const partyId = searchParams.get("partyId");
          const partyOrderId = searchParams.get("partyOrderId");

          if (token && partyOrderId && partyId) {
            saveToSessionStorage("token", token);
            saveToSessionStorage("transactionId", partyId);
            saveToSessionStorage("partyOrderId", partyOrderId);
            saveToSessionStorage(
              "requestedFor",
              RequestedForEnum.CHEF_FOR_PARTY
            );
            FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
              [EventProperties.PAGE_NAME]: ScreenNames.CHEF_FOR_PARTY_PAYMENT,
            });
            //setSaveSessionCount(true)
          } else {
            navigate("/expired");
          }
        };

        fn();
    }, [navigate, searchParams])

    useEffect(() => {
      setLoading(true);
      if (retrieveFromSessionStorage("token")) {
        (async function () {
          const customer = await customerService.getCustomerDetails()
          setUser(customer)

          const partyData = await chefForPartyService.getChefForPartyDetails(Number(retrieveFromSessionStorage("transactionId")));
          let orders =  partyData?.orders?.filter((e:any) => e.id === Number(retrieveFromSessionStorage("partyOrderId")));
          if(orders.length === 0){
            navigate("/expired", { replace: true })
          }
          setOrder(orders[0])
          if (orders[0]?.payment?.orderPayment && ![PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS].includes(orders[0]?.payment?.orderPayment?.paymentStatus)) {
            navigate("/expired", { replace: true })
          }
          setDetails(partyData)
        })();
      }else {
        navigate("/expired");
      }
      setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const RenderDiscountedPrice = (props: any) => {
        let amount = props?.data?.amount
        if(["REMAINING_PAYMENT","FULL_PAYMENT"].includes(props?.data?.orderSummary)){
          amount = Number(props?.data?.amount) - Number(props?.party?.totalDiscount)
        }
        return (
            <span style={{margin: 0, marginBottom: 2}}>
                <span style={{ color: '#FF941A' }} >
                    {`Rs ${amount} `}
                </span>
            </span>
        )
    }

    const onSuccessfulPayment = async (partyId: number, partyOrderId: number, paymentObj: any) => {
        const razorpayPayment: RazorpayPayment = {
            razorpayOrderId: paymentObj.razorpay_order_id,
            razorpayPaymentId: paymentObj.razorpay_payment_id,
            razorpaySignature: paymentObj.razorpay_signature,
            state: RazorpayTransactionStatesEnum.COMPLETED
        }
        await chefForPartyService.updatePaymentStatus(partyId, partyOrderId, razorpayPayment)
        window.location.replace(`/connecting-securely`)
    }

    const onClickPay = async () => {
        if (!details) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        const chefForParty = await chefForPartyService.initiatePayment(details!.id, order!.id , {discountCouponId: details?.discountCouponId})
      if (!chefForParty) {
          //Overwriting err EmitBus:->
            // EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            //     message: "Something Went Wrong"
            // });
            return;
        }
        saveToSessionStorage("transactionId", chefForParty!.id);
        saveToSessionStorage("requestedFor", RequestedForEnum.CHEF_FOR_PARTY);
        let orders =  chefForParty?.orders?.filter((e:any) => e.id === Number(retrieveFromSessionStorage("partyOrderId")));
        if (orders[0]?.payment?.orderPayment && orders[0]?.payment?.orderPayment?.paymentStatus === PaymentStatusEnum.PAID) {
            navigate("/success", { replace: true })
            return;
        }
        const rzpy = new Razorpay();
        const customer = await customerService.getCustomerDetails()
        customer && FirebaseCleverTabSetProfile(customer)
        FirebaseCleverTabRecordEvent(EventNames.OKANE_PAY_NOW_CLICKED, {
            [EventProperties.REFERENCE_MODEL_NAME]: order?.payment?.razorpayOrder?.referenceModelName,
            [EventProperties.AMOUNT]: Number(order?.payment?.razorpayOrder?.amount),
        });
        await rzpy.initiateRazorPayPaymentFlowForMultipleOrder({
            id: chefForParty?.id,
            childOrderId: Number(retrieveFromSessionStorage("partyOrderId")),
            amount: Number(orders[0]?.payment?.razorpayOrder?.amount),
            orderId: orders[0]?.payment?.razorpayOrder?.razorpayOrderId,
            email: customer?.email || '',
            fname: customer?.fname || '',
            mobile: customer?.mobile || '',
        }, onSuccessfulPayment);
    }

    // @ts-ignore
    return (
      <div
        className="d-flex-column-center"
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "500px",
          justifyContent: "flex-start",
          gap: 20,
        }}
      >
        <div
          className="d-flex-column-center"
          style={{ width: "100%", gap: 20 }}
        >
          <TopBar title={"Summary"} />
          <div
            className="d-flex-column-center"
            style={{
              width: "100%",
              height: "100%",
              gap: 20,
              justifyContent: "flex-start",
            }}
          >
            <CardTitle title={"Order Details"} />
            {!loading && (
              <div
                className="d-flex-column-center"
                style={{
                  width: "95%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 10,
                }}
              >
                <div className="card">
                  <ItemList
                    title="Amount"
                    action={() => `Rs ${Number(order?.amount) || "..."}`}
                  />
                    {["REMAINING_PAYMENT","FULL_PAYMENT"].includes(order?.orderSummary) && (
                        <ItemList
                            title="Discount Amount"
                            action={() => `Rs ${Number(details?.totalDiscount) || "..."}`}
                        />
                    )}
                  <div
                    style={{
                      borderTop: "dashed",
                      borderWidth: 0.5,
                      color: "#BDBDBD",
                    }}
                  />
                  <ItemList
                    title="Customer Name"
                    action={() => `${user?.fname + " " + user?.lname || "..."}`}
                  />
                  <ItemList
                    title="Customer Phone Number"
                    action={() => `${user?.mobile || "..."}`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="d-flex-column-center"
            style={{
              width: "95%",
              padding: "10px",
            }}
          >
            {!loading && (
              <ItemList
                title={<RenderDiscountedPrice data={order} party={details}/>}
                subtitle={"Net payable amount"}
                action={() => (
                  <button
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "9px",
                      padding: "12px 22px",
                      cursor: "pointer",
                      border: "none",
                    }}
                    onClick={onClickPay}
                  >
                    Pay Now
                  </button>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
}

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PaymentStatus from '../components/payments/PaymentStatus'
import { RazorpayTransactionStatesEnum } from '../enums/RazorpayTransactionStatesEnum'
import PromoService from '../services/promoService'
import SubscriptionService from '../services/subscriptionService'
import { poll, retrieveFromSessionStorage } from '../utils/utils'
import {RequestedForEnum} from "../enums/RequestedForEnum";
import TrialService from "../services/trialService";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import OmsService from "../services/omsService";
import StarCleanerService from "../services/starCleanerService";
import PlatformLeadService from '../services/platformLeadService'
import DiscountCouponService from '../services/discountCouponService'
import MarketPlaceService from '../services/marketplaceService'
import ChefForPartyService from '../services/ChefForPartyService'
const subscriptionService = new SubscriptionService();
const promoService = new PromoService();
const trialService = new TrialService();
const omsService = new OmsService();
const starCleanerService = new StarCleanerService();
const platformLeadService = new PlatformLeadService();
const discountCouponService = new DiscountCouponService()
const marketplaceService = new MarketPlaceService();
const chefForPartyService = new ChefForPartyService()

export default function PaymentPending() {
  const [txn, setTxn] = useState<any>(null)
  const navigate = useNavigate()

  const requestedForPollHandler = useCallback((transactionId: string, requestedFor: string) => {
    const omsId = retrieveFromSessionStorage("omsId");
    const starCleanerId = retrieveFromSessionStorage("starCleanerId");
    if(requestedFor === RequestedForEnum.PROMO) {
      return promoService.fetchPaymentStatus(parseInt(transactionId as string))
    } else if (requestedFor === RequestedForEnum.TRIAL) {
      return trialService.fetchPaymentStatus(parseInt(transactionId as string))
    } else if (requestedFor === RequestedForEnum.SUBSCRIPTION){
      return subscriptionService.getFullBundleDetails(parseInt(transactionId as string))
    } else if (requestedFor === RequestedForEnum.OMS){
      return omsService.getOmsDetails(Number(omsId))
    } else if (requestedFor === RequestedForEnum.STARCLEANER){
      return starCleanerService.getStarCleanerDetails(Number(starCleanerId))
    }else if (requestedFor === RequestedForEnum.DISCOUNT_COUPON){
      return discountCouponService.getDiscountCouponDetails(parseInt(transactionId as string))
    }else if (requestedFor === RequestedForEnum.PLATFORM_LEAD){
      return platformLeadService.getPlatformLeadDetails(parseInt(transactionId as string))
    }else if (requestedFor === RequestedForEnum.MARKET_PLACE) {
      return marketplaceService.getMarketPlaceOrderDetails(parseInt(transactionId as string))
    }else if (requestedFor === RequestedForEnum.CHEF_FOR_PARTY){
      return chefForPartyService.getChefForPartyDetails(parseInt(transactionId as string))
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timer;
    const transactionId = retrieveFromSessionStorage("transactionId")
    console.log(transactionId)
    const requestedFor = retrieveFromSessionStorage("requestedFor")
    poll(() => requestedForPollHandler(transactionId!,requestedFor!), setTxn)
      .then(obj => {interval = obj})
      .catch(err => navigate("/expired"))

    const timeout = setTimeout(() => {
      navigate("/progress")
    }, 30 * 1000)

    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }

  }, [navigate, requestedForPollHandler])

  useEffect(() => {
    const requestedFor: any = retrieveFromSessionStorage("requestedFor")
    if([RequestedForEnum.TRIAL,RequestedForEnum.DISCOUNT_COUPON,RequestedForEnum.PLATFORM_LEAD, RequestedForEnum.SUBSCRIPTION,RequestedForEnum.OMS,RequestedForEnum.STARCLEANER,RequestedForEnum.MARKET_PLACE].includes(requestedFor)){
      if (txn?.paymentStatus === PaymentStatusEnum.PAID) {
        navigate("/success", { replace: true })
      }
      if (txn?.paymentStatus === PaymentStatusEnum.NOT_PAID) {
        navigate("/failed", { replace: true })
      }
    }
    else if(requestedFor === RequestedForEnum.CHEF_FOR_PARTY){
      const partyOrderId = retrieveFromSessionStorage("partyOrderId")
      let order: any = txn?.orders?.filter((e:any) => e?.id === Number(partyOrderId))[0]?.payment?.orderPayment
      if (order && order?.paymentStatus === PaymentStatusEnum.PAID) {
        navigate("/success", { replace: true });
      }
      if (order && order?.paymentStatus === PaymentStatusEnum.NOT_PAID) {
        navigate("/failed", { replace: true });
      } 
    }else {
      if (txn?.state === RazorpayTransactionStatesEnum.COMPLETED) {
        navigate("/success", { replace: true })
      }

      if (txn?.state === RazorpayTransactionStatesEnum.FAILED) {
        navigate("/failed", { replace: true })
      }
    }
  }, [txn, navigate])

  return (
    <PaymentStatus
      heading='Connecting Securely'
      bottomText='Please do not press back or close the app'
      src='"https://assets10.lottiefiles.com/packages/lf20_F7WfWB.json"'
    />
  )
}

import React, { useState } from 'react'
import CustomerService from '../../services/customerService';
import UserToCookPaymentService from '../../services/userToCookPaymentQrCode';
import { UserCookMapping } from '../../interfaces/userToCookPayment/userCookMapping'
import CookService from '../../services/cookService';
import { useAppSelector } from '../../store/hooks';
import { CookGenderEnums } from '../../enums/cookGenderEnums';
import { TransactionState } from '../../enums/transactionStatus';
import { useNavigate } from 'react-router';
import { PaymentUtility } from '../../utils/paymentUtility';
import { RazorpaySuccess } from '../../interfaces/userToCookPayment/razorpaySuccess';
import { UserToCookPayment } from '../../interfaces/userToCookPayment/userCookPayment';
import { RazorpayError } from '../../interfaces/userToCookPayment/razorpayError';
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import { useParams } from 'react-router-dom';
import classes from './Styles.module.css'
import Percentage from "../../assets/percentageIcon.png"
import Arrow from "../../assets/Vector_255.png"
import { FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile } from '../../firebase';
import { EventNames } from '../../firebase/EventNames';
import { ScreenNames } from '../../firebase/ScreenNames';

export default function CookPayment() {
    const [name, setName] = useState<string>("");
    const [mobile, setMobile] = useState<string>("")
    const [amount, setAmount] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const customerService = new CustomerService();
    const userToCookPaymentService = new UserToCookPaymentService();
    const cookService = new CookService();
    const [otpResponse, setOtpResponse] = useState<boolean>(false)
    const [verifyOtp, setVerifyOtp] = useState<any>(null)
    const [userToCook, setUserToCook] = useState<UserCookMapping | null>()
    const navigate = useNavigate()
    const user = useAppSelector((state: any) => state?.customerDetails?.value)
    const paymentUtility = new PaymentUtility();
    let { cookId } = useParams();
    const [submitClick, setSubmitClick] = useState<boolean>(false)

    const getOtpHandle = async () => {
      setOtp("")
      const res = await customerService.getOtp(mobile);
      if (res) {
        setOtpResponse(true);
      }
    };

    const userCookMappinghandler = async() => {
      let customer = await customerService.getCustomerDetails();
      let nameArray = name.split(' ')
      let fname = nameArray[0];
      let lname = "";
      if(nameArray.length > 1){
        lname = nameArray[nameArray.length - 1]
      }
      customer = await customerService.updateCustomerDetails(fname, lname)
      customer && FirebaseCleverTabSetProfile(customer)
      let allUserCookMapping = await userToCookPaymentService.getAllUserCookMapping()
      for(let userToCookMap of allUserCookMapping){
        if(userToCookMap.cookId === Number(cookId)){
          setUserToCook(userToCookMap);
          if(userToCookMap?.monthlyAmount){
            setAmount(userToCookMap?.monthlyAmount)
          }
        }
      }
    }

    const verifyOtpHandle = async () => {
      if (otp) {
        try {
          let verifiedRes = await customerService.verifyOtp(mobile, otp);
          setVerifyOtp(verifiedRes);
          await userCookMappinghandler();
        } catch (err) {
          console.log(err);
        }
      }
    };

    const razorPaySuccessCallBack = async (
      data: RazorpaySuccess,
      transactionDetails: UserToCookPayment,
    ) => {
      try {
        let res = await userToCookPaymentService.updateUserToCookPayment(transactionDetails?.id, data);
        if(res?.razorpayOrder?.state === TransactionState.PAID){
          navigate("/success")
        }else{
          navigate("/progress", { state: { userToCookPayment: true } });
        }
      } catch (error) {
        console.log((error as Error).message);
      }
    };

    const razorPayFailureCallBack = async (
      error: RazorpayError,
      transactionDetails: UserToCookPayment,
    ) => {
      try {
        await userToCookPaymentService.updateUserToCookPayment(
          transactionDetails?.id,
          undefined,
          transactionDetails?.razorpayOrder?.razorpayOrderId,
        );
        navigate('/failed');
      } catch (e: any) {
        console.log(error)
      }
    };

    const handleSumbit = async() => {
      let createUserToCookPayment = null
      let createUserCookMapping = userToCook;
      setSubmitClick(true)
      if(!userToCook){
        const cookDetails = await cookService.getCookDetails(Number(cookId))
        createUserCookMapping = await userToCookPaymentService.createUserCookMapping(cookDetails?.mobile, `${cookDetails.fname} ${cookDetails.lname}`, cookDetails.gender as CookGenderEnums, amount)
        setUserToCook(createUserCookMapping)
      }
      try{
        createUserToCookPayment = await userToCookPaymentService.createUserToCookPayment(amount, createUserCookMapping!.id)
        if(createUserToCookPayment?.userPaymentState === TransactionState.PAID){
          navigate("/success")
        }else if (createUserToCookPayment && createUserToCookPayment?.razorpayOrder) {
          await paymentUtility.initatePaymentForCookPayment(
            user.customer,
            createUserToCookPayment,
            razorPaySuccessCallBack,
            razorPayFailureCallBack,
          );
        }
      }catch(err: any){
        if (err?.response?.status === 400) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.error || 'Something went Wrong',
          });
          return;
        }
      }finally{
        setSubmitClick(false)
      }
    } 
   
    return (
      <div
        className="d-flex-column-center"
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "500px",
          justifyContent: "flex-start",
          gap: 20,
        }}
      >
        <div className={classes.topIconCard}>
          <img src={Percentage} alt={"icon"} className={classes.percentageIcon} />
          <div className={classes.mainTextContainer}>
            <p className={classes.mainHeading}>Pay your cook </p>
            <p className={classes.mainSubHeading}>
              help your cook in becoming self -dependent
            </p>
          </div>
        </div>
        <div className={classes.formCard}>
          <div className={classes.formContainer}>
            <p className={classes.topHeading}>Fill details to make payment</p>
            <label className={classes.lable}>Your Name*</label>
            <input
              type="text"
              id="name"
              disabled={otpResponse}
              className={classes.nameInput}
              onChange={(e: any) => {
                FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_NAME_EDITED, {
                  screenName: ScreenNames.COOK_PAYMENT,
                });
                setName(e.target.value);
              }}
              placeholder="Full Name"
            />
            <div className={classes.formItem}>
              <label className={classes.lable}>Mobile Number*</label>
              <div className={classes.mobileInputContainer}>
                <input
                  type="number"
                  id="mobile"
                  min={0}
                  disabled={otpResponse}
                  onChange={(e: any) => {
                    FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_MOBILE_EDITED, {
                      screenName: ScreenNames.COOK_PAYMENT,
                    });
                    setMobile(e.target.value);
                  }}
                  className={classes.mobileInput}
                  placeholder="10 digit phone no."
                />
                <button
                  className={`${classes.verifyOtpBtn} ${
                    otpResponse ? classes.resendText : null
                  }`}
                  disabled={mobile?.length < 10 || mobile?.length > 10 || name?.length === 0}
                  onClick={() => {
                    FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_GET_OTP_CLICKED, {
                      screenName: ScreenNames.COOK_PAYMENT,
                      fullName: name,
                      mobile: mobile,
                    });
                    getOtpHandle();
                  }}
                >
                  {otpResponse ? "Resend OTP" : "Send OTP"}
                </button>
              </div>
            </div>
            {!verifyOtp && otpResponse && (
              <div className={classes.formItem}>
                <label className={classes.lable}>Enter OTP</label>
                <div className={classes.mobileInputContainer}>
                  <input
                    type="tel"
                    id="otp"
                    min={0}
                    value={otp}
                    maxLength={6}
                    onChange={(e: any) => setOtp(e.target.value)}
                    className={classes.mobileInput}
                    placeholder="Enter the OTP"
                  />
                  <button
                    className={classes.verifyOtpBtn}
                    onClick={() => {
                      FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_VERIFY_OTP_CLICKED,{
                        screenName: ScreenNames.COOK_PAYMENT,
                        otp: otp,
                      });
                      verifyOtpHandle();
                    }}
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}
            {verifyOtp && (
              <div className={classes.submitConatiner}>
                <label className={classes.lable}>Amount to Pay*</label>
                <input
                  type="number"
                  id="cookAmount"
                  min={0}
                  value={amount}
                  disabled={!verifyOtp}
                  onChange={(e: any) => {
                    FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_COOK_SALARY_EDITED, {
                      screenName: ScreenNames.COOK_PAYMENT,
                    });
                    setAmount(e.target.value);
                  }}
                  className={classes.amountInput}
                  placeholder="Enter the amount here.."
                />
                <button
                  onClick={() => {
                    FirebaseCleverTabRecordEvent(EventNames.USER_DETAILS_PAY_NOW_CLICKED, {
                      screenName: ScreenNames.COOK_PAYMENT,
                      cookSalary: amount
                    });
                    handleSumbit();
                  }}
                  disabled={submitClick}
                  className={classes.submitBtn}
                >
                  Continue to pay
                  <span>
                    <img className={classes.arrow} src={Arrow} alt="arrow" />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

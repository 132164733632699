export enum ScreenNames {
    COOK_PAYMENT = "Cook payment screen",
    PAYMENT_POST_SUCCESS = "Payment post success screen",
    SUBSCRIPTION_PAYMENT = "Subscription payment screen",
    TRIAL_PAYMENT = "Trial payment screen",
    OMS_PAYMENT = "One meal service payment screen",
    POST_SUCCESS = "Payment post success page",
    PAYMENT_SUCCESS = "Payment success page",
    PAYMENT_FAILED = "Payment failed page",
    STAR_CLEANER_PAYMENT = "Star cleaner payment screen",
    COUPON_PAGE = "Select coupon screen",
    DISCOUNT_COUPON_PAYMENT = "Discount Coupon Payment Screen",
    PLATFORM_LEAD_PAYMENT = "Platform lead Payment Screen",
    MARKET_PLACE_PAYMENT = "Market Place Payment Screen",
    CHEF_FOR_PARTY_PAYMENT = "Chef for Party Payment Screen"
}

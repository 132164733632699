import React from 'react';
interface InputProps {
    src?: string;
    title?: string;
    subTitle?: string;
    list?: any;
    bgColor?: string;
}

export default function PromoBanner(props: InputProps) {
    return (
        <div
            className='d-flex-column-center'
            style={{
                width: '100%',
                position: 'relative'
            }}
        >
            <img style={{ width: '100%' }} src={props.src} alt={"status-logo"} />
        </div>
    )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ItemList from '../components/ItemList'
import { EventBusEventsEnum } from '../enums/EventBusEventsEnum'
import EventBus from '../EventBus'
import Razorpay from '../integrations/Razorpay'
import Coupon from '../interfaces/coupon'
import {MealMappingInterface} from '../interfaces/userBundleMapping'
import CustomerService from '../services/customerService'
import { calculateNetPrice, getMealString, retrieveFromSessionStorage, saveToSessionStorage } from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import ClickableCardItem from "../components/common/ClickableCardItem";
import TrialService from "../services/trialService";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import {TrialResponse} from "../interfaces/TrialsResponse";
import {RequestedForEnum} from "../enums/RequestedForEnum";
import RazorpayPayment from "../interfaces/razorpayPayment";
import {RazorpayTransactionStatesEnum} from "../enums/RazorpayTransactionStatesEnum";
import {FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {ScreenNames} from "../firebase/ScreenNames";
import {EventProperties} from "../firebase/EventProperties";

export default function TrialPayment() {

    const [trialDetail, setTrialDetail] = useState<TrialResponse>()
    const [selectedCoupon, setSelectedCoupon] = useState<Coupon>()
    const [netPrice, setNetPrice] = useState({
        amount: "0.00",
        discount: "0.00"
    })
    const [loading, setLoading] = useState<boolean>(true)

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const trialService = useMemo(() => new TrialService(), [])

    const onCouponApply = useCallback((
        async (couponCode: string, trialDetail: TrialResponse) => {
            const coupon = await trialService.verifyCoupon({ couponCode: couponCode });
            setSelectedCoupon(coupon);
            const netPrice = calculateNetPrice(trialDetail!.trialAmount, coupon);
            setNetPrice(netPrice);
        }
    ),[trialService])

    useEffect(() => {
        const fn = async () => {
            setLoading(true);
            const token = searchParams.get("token");
            const trialId = searchParams.get("trialId");
            const couponCode = searchParams.get("couponCode");

            if (token && trialId) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("trialId", trialId)
                    saveToSessionStorage("requestedFor", RequestedForEnum.TRIAL);
                    FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                        [EventProperties.PAGE_NAME]: ScreenNames.TRIAL_PAYMENT,
                    });

                    const trialDetail = await trialService.getTrialDetails(Number(trialId));

                    if (![PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS].includes(trialDetail.paymentStatus)) {
                        navigate("/expired", { replace: true })
                    }
                    setTrialDetail(trialDetail)
                    if(couponCode && !trialDetail?.razorpayOrderId){
                        onCouponApply(couponCode, trialDetail);
                    }
                } catch (err) {
                    navigate("/expired", { replace: true })
                }
                setLoading(false);
            }
        }

        fn()
    }, [navigate, onCouponApply, searchParams, trialService])

    const RenderDiscountedPrice = () => {
        if (!selectedCoupon?.couponCode) {
            return <span style={{ margin: 0 }}>Rs. {trialDetail?.razorpayOrderId ? trialDetail?.razorpayOrder?.amount : trialDetail?.trialAmount}</span>
        }

        return (
            <span style={{margin: 0, marginBottom: 2}}>
                <span style={{ color: '#FF941A' }} >
                    {`Rs ${parseInt(netPrice.amount) < 0 ? "0.00" : netPrice.amount} `}
                </span>
                <span
                    style={{
                        textDecoration: 'line-through',
                        marginRight: '5px',
                        fontStyle: 'italic',
                        fontSize: 12
                    }}
                >
                    {`Rs ${trialDetail?.trialAmount}`}
                </span>
            </span>
        )
    }

    const onSuccessfulPayment = async (transactionId: number, paymentObj: any) => {
        const razorpayPayment: RazorpayPayment = {
            razorpayOrderId: paymentObj.razorpay_order_id,
            razorpayPaymentId: paymentObj.razorpay_payment_id,
            razorpaySignature: paymentObj.razorpay_signature,
            state: RazorpayTransactionStatesEnum.COMPLETED
        }
        await trialService.updatePaymentStatus(transactionId, razorpayPayment)
        window.location.replace(`/connecting-securely?requestedFor=${RequestedForEnum.TRIAL}`)
    }

    const onClickPay = async () => {
        const customerService = new CustomerService()
        if (!trialDetail) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        const transactionV3Obj = await trialService.initiatePayment(trialDetail!.id, selectedCoupon?.id)
            .catch(err => {
                console.log(err)
            })
        if (!transactionV3Obj) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Something Went Wrong"
            });
            return;
        }
        saveToSessionStorage("transactionId", transactionV3Obj!.id);
        saveToSessionStorage("requestedFor", RequestedForEnum.TRIAL);
        if (transactionV3Obj.paymentStatus === PaymentStatusEnum.PAID) {
            navigate("/success", { replace: true })
            return;
        }
        const rzpy = new Razorpay();
        const customer = await customerService.getCustomerDetails()
        customer && FirebaseCleverTabSetProfile(customer)
        FirebaseCleverTabRecordEvent(EventNames.OKANE_PAY_NOW_CLICKED, {
            [EventProperties.REFERENCE_MODEL_NAME]: transactionV3Obj?.razorpayOrder?.referenceModelName,
            [EventProperties.AMOUNT]: transactionV3Obj?.razorpayOrder?.amount,
        });
        await rzpy.initiateRazorPayPaymentFlow({
            id: transactionV3Obj?.id,
            amount: Number(transactionV3Obj?.trialAmount),
            orderId: transactionV3Obj.razorpayOrder?.razorpayOrderId,
            email: customer?.email || '',
            fname: customer?.fname || '',
            mobile: customer?.mobile || '',
        }, onSuccessfulPayment);
    }

    // @ts-ignore
    return (
        <div
            className='d-flex-column-center'
            style={{
                width: '100%',
                height: '100%',
                maxWidth: '500px',
                justifyContent: 'flex-start',
                gap: 20,
            }}>
            <div className='d-flex-column-center' style={{ width: '100%', gap: 20}}>
            <TopBar title={'Summary'}/>
            <div
                className='d-flex-column-center'
                style={{
                    width: '100%',
                    height: '100%',
                    gap: 20,
                    justifyContent: 'flex-start',
                }}>
                <CardTitle title={'Trial Details'}/>
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '95%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 10
                    }}>
                    <div className="card">
                        <ItemList
                            title='Trial Price'
                            action={() => `Rs ${trialDetail?.trialAmount}`}
                        />
                        <div style={{ borderTop: 'dashed', borderWidth: 0.5, color: '#BDBDBD'  }} />

                        <ItemList
                            title='Total Members'
                            action={() => trialDetail?.totalPeople}
                        />

                        {trialDetail?.mealMapping && trialDetail?.mealMapping?.map((item: MealMappingInterface) => {
                            return (
                                <ItemList
                                    key={item.slot}
                                    title={getMealString(item.slot)}
                                    action={() => `${item.time}`}
                                />
                            )
                        })}

                    </div>
                </div>
                {loading ? '' : !trialDetail?.razorpayOrderId && (
                    <CardTitle title={'Offers & Benefits'}/>
                )}
                {loading ? '' : !trialDetail?.razorpayOrderId && (
                    <div
                        className='d-flex-column-center'
                        style={{
                            width: '95%',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 10
                        }}>
                        <div className="card">
                            {
                                selectedCoupon ?
                                    <ClickableCardItem
                                        title={selectedCoupon?.couponCode || '---'}
                                        subtitle={`Rs ${netPrice?.discount} discount added`}
                                        action={() => {
                                            setSelectedCoupon(undefined);
                                            setNetPrice({
                                                amount: "0.00",
                                                discount: "0.00"
                                            });
                                            const token = retrieveFromSessionStorage("token")
                                            const trialId = retrieveFromSessionStorage("trialId");
                                            navigate(`/trial-pay?token=${token}&trialId=${trialId}`, { replace: true });
                                        }}
                                        actionText={'Remove'}
                                    /> :
                                    <ClickableCardItem
                                        title={'Apply Coupons'}
                                        subtitle={'Enjoy benefits with our exclusive offers'}
                                        action={() => {
                                            const token = retrieveFromSessionStorage("token")
                                            const trialId = retrieveFromSessionStorage("trialId");
                                            navigate(`/trial-coupon?token=${token}&trialId=${trialId}`);
                                        }}
                                        actionText={'❯'}
                                    />
                            }
                        </div>
                    </div>
                )}
            </div>
            </div>
            <div style={{ width: '100%'}}>
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '95%',
                        padding: '10px'
                    }}>
                    <ItemList
                        title={<RenderDiscountedPrice />}
                        subtitle={'Net payable amount'}
                        action={
                            () => <button
                                style={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    borderRadius: '9px',
                                    padding: '12px 22px',
                                    cursor: 'pointer',
                                    border: 'none'
                                }}
                                onClick={onClickPay}
                            >
                                Pay Now
                            </button>}
                    />
                </div>
            </div>
        </div>
    )
}

import moment from "moment";
import { CookGenderEnums } from "../enums/cookGenderEnums";
import HttpClient from "../HttpClient";
import { RazorpaySuccess } from "../interfaces/userToCookPayment/razorpaySuccess";
import { UserCookMapping } from "../interfaces/userToCookPayment/userCookMapping";
import { UserToCookPayment } from "../interfaces/userToCookPayment/userCookPayment";
import { SetActiveUserCookMappingAction, SetAllUserCookMappingAction, SetUserToCookPaymentAction } from "../store/actions/userToCookPayment/userToCookPaymentActions";

export default class UserToCookPaymentService {

    async getAllUserCookMapping(): Promise<UserCookMapping[]> {
        const res = await HttpClient.get(`/user-cook-mapping/customer/mapping/all`);
        if(res?.status === 200){
            SetAllUserCookMappingAction(res?.data)
            return res?.data;
        }
        return [];
    }

    async getUserCookMappingById(userCookMappingId: number): Promise<UserCookMapping> {
        const res = await HttpClient.get(`/user-cook-mapping/customer/mapping/${userCookMappingId}`);
        return res?.data;
    }

    async createUserCookMapping(cookMobile: string, cookName: string, gender: CookGenderEnums, monthlyAmount: string): Promise<UserCookMapping | null> {
        let payload = {
            cookMobile,
            cookName,
            monthlyAmount,
            gender,
            salaryPaymentDay : Number(moment().format("DD"))
        }
        const res = await HttpClient.post(`/user-cook-mapping/customer/mapping`, payload);
        if(res?.status === 200){
            SetActiveUserCookMappingAction(res?.data)
            return res?.data;
        }
        return null;
    }

    async createUserToCookPayment(amount: string, userCookMappingId: number): Promise<UserToCookPayment | null> {
        let data = {
            walletBalanceUsed: "0.00",
            amount
        }
        const res = await HttpClient.post(`/user-cook-payment/v2/customer/cook-mapping/${userCookMappingId}`, data, {params: {paymentInitiatedBy: "COOK"}});
        if(res?.status === 200){
            SetUserToCookPaymentAction(res?.data)
            return res?.data;
        }
        return null;
        
    }

    async updateUserToCookPayment(userToCookPaymentId: number, razorPayResponse?: RazorpaySuccess, razorpayOrderId?: string) {
        let resBody;
        if (razorpayOrderId) {
          resBody = { razorpayOrderId: razorpayOrderId };
        } else {
          resBody = {
            razorpayPaymentId: razorPayResponse?.razorpay_payment_id
              ? razorPayResponse?.razorpay_payment_id
              : null,
            razorpayOrderId: razorPayResponse?.razorpay_order_id
              ? razorPayResponse?.razorpay_order_id
              : null,
            razorpaySignature: razorPayResponse?.razorpay_payment_id
              ? razorPayResponse?.razorpay_signature
              : null,
          };
        }
        const res = await HttpClient.patch(
          `/user-cook-payment/customer/payment/${userToCookPaymentId}`,
          {
            state: razorpayOrderId ? "FAILED" : "COMPLETED",
            ...resBody,
          }
        );
        if(res?.status === 200){
            SetUserToCookPaymentAction(res?.data)
            return res?.data;
        }
        return null;
    }

    async pollingUserCookPayment(userToCookPaymentId: number) {
        const res = await HttpClient.get(`/user-cook-payment/customer/payment/${userToCookPaymentId}`);
        if(res?.status === 200){
            SetUserToCookPaymentAction(res?.data)
            return res?.data;
        }
        return null;
    }
}

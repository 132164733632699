import React from 'react';

interface InputProps { 
    title: any; 
    subtitle?: string 
    action?: any 
}

export default function ItemList(props: InputProps) {
    return (
        <div className='d-flex-center' style={{ width: '100%', justifyContent: 'space-between'}}>
            <div>
                <p style={{fontSize: '15px', margin: 0}}> {props.title} </p>
                <p style={{fontSize: '12px', margin: 0}}> {props.subtitle} </p>
            </div>
            <div> {props.action && <props.action />} </div>
        </div>
    )
}

import HttpClient from "../HttpClient";
import {TransactionV3} from "../interfaces/transactionV3";
import RazorpayPayment from "../interfaces/razorpayPayment";

export default class PlatformLeadService {

    async getPlatformLeadDetails(platformLeadId: number){
        const res = await HttpClient.get(`/customer/platform-lead/${platformLeadId}`);
        if (res?.status === 200){
            return res?.data;
        }
        return null;
    }

    async initiatePayment(platformLeadId: number): Promise<TransactionV3> {
        const res = await HttpClient.post(`/customer/platform-lead/${platformLeadId}/razorpay-order`);
        return res?.data;
    }

    async updatePaymentStatus(platformLeadId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/customer/platform-lead/${platformLeadId}/razorpay-order`, data);
        return res?.data;
    }

}

import {configureStore} from "@reduxjs/toolkit"
import customerDetails from "./reducers/customerDetails";
import qrCookPaymentDetails from "./reducers/qrCookPayment";

const store = configureStore({
    reducer: {
        qrCookPayment: qrCookPaymentDetails,
        customerDetails: customerDetails
    }
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router';
import ProgressClock from "../assets/pending_large.png"
import PaymentStatus from '../components/payments/PaymentStatus'
import { TransactionState } from '../enums/transactionStatus';
import { UserToCookPayment } from '../interfaces/userToCookPayment/userCookPayment';
import UserToCookPaymentService from '../services/userToCookPaymentQrCode';
import { useAppSelector } from '../store/hooks';

export default function PaymentProgress() {
    const { state } : any = useLocation();
    const userToCookPaymentService = new UserToCookPaymentService();
    const transactionResponse = useAppSelector((state) => state?.qrCookPayment?.value?.userToCookPayment)
    const intervalId = useRef<number>(0);
    let timeoutId = useRef<number>();
    const navigate = useNavigate()

    const checkStatus = (
        _transactionResponse: UserToCookPayment,
        status?: string,
      ) => {
        if (_transactionResponse?.userPaymentState === TransactionState.PAID) {
          if (intervalId.current) {
            clearInterval(intervalId.current);
          }
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          navigate('/success');
        }
        if (
          _transactionResponse?.userPaymentState === TransactionState.CREATED ||
          _transactionResponse?.userPaymentState === TransactionState.ATTEMPTED
        ) {
          if (intervalId.current) {
            clearInterval(intervalId.current);
          }
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          navigate("/progress", { state: { userToCookPayment: true } });
        }
        if (status === 'RESPONSE_DELAY') {
          if (intervalId.current) {
            clearInterval(intervalId.current);
          }
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          navigate("/progress", { state: { userToCookPayment: true } });
        }
      };

    const getTransactionData = async (transactionResponseObj: UserToCookPayment) => {
        try {
          if (transactionResponseObj) {
            if(transactionResponseObj?.userPaymentState !== TransactionState.PAID){
              let response = await userToCookPaymentService.pollingUserCookPayment(transactionResponseObj?.id);
              checkStatus(response, transactionResponseObj.userPaymentState);
            }
            checkStatus(transactionResponseObj, transactionResponseObj.userPaymentState);
          }
        } catch (error) {}
      };

      useEffect(() => {
        if (state?.userToCookPayment) {
          intervalId.current = window.setInterval(() => {
            getTransactionData(transactionResponse!);
          }, 5000);
          if (!timeoutId?.current) {
            timeoutId.current = window.setTimeout(() => {
              if (intervalId.current) {
                clearInterval(intervalId.current);
              }
              if (transactionResponse) {
                checkStatus(transactionResponse, "RESPONSE_DELAY");
              }
            }, 30000);
          }
        }

        return function () {
          if (intervalId) {
            if (intervalId.current) {
              clearInterval(intervalId.current);
            }
          }
          if (timeoutId) {
            if (timeoutId.current) {
              clearTimeout(timeoutId.current);
            }
          }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    //TODO: timer

    return (
        <PaymentStatus
            heading='Payment In Progress'
            subheading='Please wait while the payment is being processed.'
            icon={ProgressClock}
            
        />
    )
}
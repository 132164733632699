import {createSlice} from "@reduxjs/toolkit";

export const qrCookPaymentSlice = createSlice({
    name: 'qrCookPaymentDetails',
    initialState: {
        value: {
            allUserCookMapping: [],
            activeUserCookMapping: null,
            userToCookPayment: null
        }
    },
    reducers: {
        setAllUserCookMapping: (state, action) => {
            state.value.allUserCookMapping = action.payload;
        },
        setActiveUserCookMapping: (state, action) => {
            state.value.activeUserCookMapping = action.payload;
        },
        setUserToCookPayment: (state, action) => {
            state.value.userToCookPayment = action.payload;
        }
    }
})

export const {setAllUserCookMapping, setActiveUserCookMapping, setUserToCookPayment} = qrCookPaymentSlice.actions;

export default qrCookPaymentSlice.reducer;

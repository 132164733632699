import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import clevertap from 'clevertap-web-sdk'
import Customer from "../interfaces/customer";
import {EventNames} from "./EventNames";

const firebaseConfigProduction = {
    apiKey: "AIzaSyB4YiZWokYvdiw1wNg4HlrARHbUNgWmOqU",
    authDomain: "okane-84a3d.firebaseapp.com",
    projectId: "okane-84a3d",
    storageBucket: "okane-84a3d.appspot.com",
    messagingSenderId: "169609205841",
    appId: "1:169609205841:web:e093839d4237b4e103c070",
    measurementId: "G-J15HNSH1Q8"
}

const firebaseConfigDevelopment = {
    apiKey: "AIzaSyAu7We5SR-gOjXJC7BNxfBRa58sezpJAcE",
    authDomain: "okane-staging.firebaseapp.com",
    projectId: "okane-staging",
    storageBucket: "okane-staging.appspot.com",
    messagingSenderId: "520187994709",
    appId: "1:520187994709:web:f62238de05a3437216aadc",
    measurementId: "G-76F1PDRB84"
}

const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? firebaseConfigProduction : firebaseConfigDevelopment;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);

const cleverTapConfigProduction = {
    ACCOUNT_ID: "8RK-Z9R-W56Z"
}

const cleverTapConfigDevelopment = {
    ACCOUNT_ID: "TEST-9RK-Z9R-W56Z"
}

const cleverTapConfig = process.env.REACT_APP_ENV === 'production' ? cleverTapConfigProduction : cleverTapConfigDevelopment;

const firebaseAnalytics = getAnalytics(app);

clevertap.privacy.push({optOut: false})
clevertap.privacy.push({useIP: false})
clevertap.init(cleverTapConfig.ACCOUNT_ID)

export const FirebaseCleverTabRecordEvent = (eventName: string, params?: {[key: string]: any}) => {
    clevertap.event.push(eventName, {...params})
    logEvent(firebaseAnalytics, eventName, params)
}

export const FirebaseCleverPurchaseEvent = (razorpayOrder: any) => {
    if(!razorpayOrder) return;
    const params = {
        currency: razorpayOrder?.currency,
        value: Number(razorpayOrder?.amount || '0'),
        transaction_id: razorpayOrder?.razorpayOrderId,
        items: [
            {
                item_name: razorpayOrder?.referenceModelName,
                item_id: razorpayOrder?.referenceId?.toString(),
                price: Number(razorpayOrder?.amount || '0'),
            },
        ],
    }
    FirebaseCleverTabRecordEvent(EventNames.PURCHASE, params);
};

export const FirebaseCleverTabSetProfile = (customerProfile: Customer) => {
    const userProperties = {
        Name:
          customerProfile.fname +
            ' ' +
            (customerProfile.mname || '') +
            ' ' +
            customerProfile.lname || '',
        Identity: customerProfile?.id?.toString(),
        Email: customerProfile?.email ?? '',
        HouseId: customerProfile?.houses[0]?.houseId ?? '',
        Address: customerProfile?.houses[0]?.address ?? '',
        City: customerProfile?.houses[0]?.cityName ?? '',
        Locality: customerProfile?.houses[0]?.localityName ?? '',
        Mobile: customerProfile?.mobile,
        Location: customerProfile?.houses[0]?.cityName ?? '',
      };
    clevertap.onUserLogin.push({Site: userProperties})
    setUserProperties(firebaseAnalytics, userProperties)
}

//30 minutes
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 30;

export default remoteConfig;

import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import Coupon from '../interfaces/coupon'
import SubscriptionService from '../services/subscriptionService'
import {retrieveFromSessionStorage, saveToSessionStorage} from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import OmsService from "../services/omsService";
import {ProductTypes} from "../enums/ProductTypesEnum";
import {RequestedForEnum} from "../enums/RequestedForEnum";
import StarCleanerService from "../services/starCleanerService";
import {FirebaseCleverTabRecordEvent} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {EventProperties} from "../firebase/EventProperties";
import {ScreenNames} from "../firebase/ScreenNames";
import NewClickableCard from '../components/common/NewClickableCard'
import CommonService from '../services/commonService'
import moment from 'moment'

const omsService = new OmsService();
const starCleanerService = new StarCleanerService();
const commonService = new CommonService();

export default function ChooseCoupon() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const subscriptionService = useMemo(() => new SubscriptionService(), []);

    const [offers, setOffers] = useState<Coupon[]>([]);
    const [couponCode, setCouponCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fn = async () => {
            setLoading(true);
            const token = searchParams.get("token");
            const userBundleMappingId = searchParams.get("userBundleMappingId");
            const serviceId = searchParams.get("serviceId");
            const requestedFor = retrieveFromSessionStorage("requestedFor")
            if (token && userBundleMappingId) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("userBundleMappingId", userBundleMappingId)
                    FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                        [EventProperties.PAGE_NAME]: ScreenNames.COUPON_PAGE,
                    });
                    const userBundleMapping = await subscriptionService.getFullBundleDetails(Number(userBundleMappingId));
                    if (![PaymentStatusEnum.NOT_PAID].includes(userBundleMapping?.paymentStatus)) {
                        navigate("/expired", { replace: true })
                    }
                    const coupons = await commonService.fetchAllActiveCouponsByHouseId(Number(userBundleMapping?.houseId), ProductTypes.SUBSCRIPTION,moment().format('YYYY-MM-DD'));
                    const couponData = coupons.filter((eachCoupon) => Number(eachCoupon?.minimumOrderValue) <= Number(userBundleMapping?.quotedNetPrice));
                    setOffers(couponData);
                    setLoading(false);
                } catch (err) {
                    navigate("/expired")
                }
            }
            if (token && serviceId && requestedFor === RequestedForEnum.OMS) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("omsId", serviceId)
                    const omsData = await omsService.getOmsDetails(Number(serviceId));
                    if (![PaymentStatusEnum.NOT_PAID].includes(omsData?.paymentStatus)) {
                        navigate("/expired", { replace: true })
                    }
                    const coupons = await commonService.fetchAllActiveCouponsByHouseId(parseInt(omsData.houseId), ProductTypes.OMS,omsData?.date);
                    const couponData = coupons.filter((eachCoupon) => Number(eachCoupon?.minimumOrderValue) <= Number(omsData?.oneMealServiceAmount));
                    setOffers(couponData);
                    setLoading(false);
                } catch (err) {
                    navigate("/expired")
                }
            }
            if (token && serviceId && requestedFor === RequestedForEnum.STARCLEANER) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("starCleanerId", serviceId)
                    const starCleanerData = await starCleanerService.getStarCleanerDetails(Number(serviceId));
                    if (![PaymentStatusEnum.NOT_PAID].includes(starCleanerData?.paymentStatus)) {
                        navigate("/expired", { replace: true })
                    }
                    const coupons = await commonService.fetchAllActiveCouponsByHouseId(parseInt(starCleanerData.houseId), ProductTypes.STAR_CLEANER,starCleanerData?.visitDate);
                    const couponData = coupons.filter((eachCoupon) => Number(eachCoupon?.minimumOrderValue) <= Number(starCleanerData?.amount));
                    setOffers(couponData);
                    setLoading(false);
                } catch (err) {
                    navigate("/expired")
                }
            }
        }
        fn()
    }, [navigate, searchParams, subscriptionService])

    const onClickVerify =  async (couponCode: string) => {
        const requestedFor = retrieveFromSessionStorage("requestedFor")
        const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId")
        if(requestedFor === RequestedForEnum.SUBSCRIPTION){
            await subscriptionService.verifyCoupon({
                userBundleId: Number(userBundleMappingId)!,
                couponCode: couponCode,
                productType: ProductTypes.SUBSCRIPTION,
            }).then((res) =>{
                if(res){
                    onCouponClick(res);
                }
            });
        } else if(requestedFor === RequestedForEnum.OMS){
            await omsService.verifyCoupon({
                couponCode: couponCode,
                productType: ProductTypes.OMS,
            }).then((res) =>{
                if(res){
                    onCouponClick(res);
                }
            });
        } else if(requestedFor === RequestedForEnum.STARCLEANER){
            await starCleanerService.verifyCoupon({
                couponCode: couponCode,
                productType: ProductTypes.STAR_CLEANER,
            }).then((res) =>{
                if(res){
                    onCouponClick(res);
                }
            });
        }
    };

    const onCouponClick = (offer: Coupon) => {
        FirebaseCleverTabRecordEvent(EventNames.OKANE_COUPON_APPLIED, {
            [EventProperties.COUPON_TYPE]: offer?.productType,
            [EventProperties.COUPON_AMOUNT]: offer?.couponValue,
        });
        const token = retrieveFromSessionStorage("token");
        const requestedFor = retrieveFromSessionStorage("requestedFor")
        const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId");
        const omsId = retrieveFromSessionStorage("omsId");
        const starCleanerId = retrieveFromSessionStorage("starCleanerId");
        if(requestedFor === RequestedForEnum.SUBSCRIPTION) {
            navigate(`/pay?token=${token}&userBundleMappingId=${userBundleMappingId}&couponCode=${offer?.couponCode}`)
        } else if(requestedFor === RequestedForEnum.OMS) {
            navigate(`/oms-pay?token=${token}&serviceId=${omsId}&couponCode=${offer?.couponCode}`)
        } else if(requestedFor === RequestedForEnum.STARCLEANER) {
            navigate(`/star-cleaner-pay?token=${token}&serviceId=${starCleanerId}&couponCode=${offer?.couponCode}`)
        }
    }

    const onBackClick = () => {
        const token = retrieveFromSessionStorage("token");
        const requestedFor = retrieveFromSessionStorage("requestedFor")
        const userBundleMappingId = retrieveFromSessionStorage("userBundleMappingId");
        const omsId = retrieveFromSessionStorage("omsId");
        const starCleanerId = retrieveFromSessionStorage("starCleanerId");
        if(requestedFor === RequestedForEnum.SUBSCRIPTION){
            navigate(`/pay?token=${token}&userBundleMappingId=${userBundleMappingId}`)
        } else if(requestedFor === RequestedForEnum.OMS){
            navigate(`/oms-pay?token=${token}&serviceId=${omsId}`)
        } else if(requestedFor === RequestedForEnum.STARCLEANER) {
            navigate(`/star-cleaner-pay?token=${token}&serviceId=${starCleanerId}`);
        }
    }

    return (
        <div
            className='d-flex-column-center'
            style={{
                width: '100%',
                height: '100%',
                maxWidth: '500px',
                justifyContent: 'flex-start',
                gap: 10,
            }}>
            <TopBar title={'Apply Coupon'}/>
            <CardTitle title={'Enter the Coupon Code'}/>
            <div style={{width: '100%'}}>
                <input
                    type="text"
                    placeholder={'Enter the coupon code here'}
                    id="coupon-field"
                    name="fname"
                    style={{width: '70%', marginLeft: 16, marginRight: 8, padding: 9}}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                />
                <button
                    style={{
                        width: '20%',
                        color: 'white',
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        padding: '10px',
                        cursor: 'pointer',
                        border: 'none',
                        marginBottom: 10,
                    }}
                    onClick={() => {onClickVerify(couponCode.trim())}}
                    disabled={couponCode.trim() === ''}
                >
                    APPLY
                </button>
            </div>
            <CardTitle title={'Select from Available Coupons'}/>
            <div style={{ overflowX:'scroll',scrollBehavior:"smooth",width:"100%"}}>
                <div
                    className='d-flex-column-center'
                    style={{
                        width: '100%',
                        gap: 10,
                    }}>
                    {
                        !!offers && Array.isArray(offers) && offers?.length > 0 ?
                            offers?.map(offer => {
                            return (
                                <div className="new-card" key={offer?.couponCode}>
                                    <NewClickableCard offer={offer} onCouponClick={onCouponClick}/>
                                </div>
                            )
                        }) : (!loading ?
                            <div className="card"
                                style={{
                                    alignItems: 'center',
                                }}>
                                <p>No Coupons Available</p>
                                <button
                                    style={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                        borderRadius: '9px',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        border: 'none',
                                        marginBottom: 10,
                                    }}
                                    onClick={onBackClick}
                                >
                                    Go Back
                                </button>
                            </div> :
                            ''
                        )
                    }
                </div>
        </div>
        </div>
    )
}

import HttpClient from "../HttpClient";
import Coupon from "../interfaces/coupon";
import RazorpayPayment from "../interfaces/razorpayPayment";
import { TransactionV3 } from "../interfaces/transactionV3";
import UserBundleMapping from "../interfaces/userBundleMapping";
import VerifyCoupon from "../interfaces/verifyCoupon";

export default class SubscriptionService {
    async initiatePayment(userBundleMappingId: number, couponId?: number): Promise<TransactionV3> {
        let body = {}

        if (couponId) {
            body = {
                discountCouponId: couponId,
                version: 2, // this is hard-coded because of Coupon v2
            }
        }

        const res = await HttpClient.post(`/v3/customer/bundle/userBundles/${userBundleMappingId}/razorpay-order`, body);
        return res?.data;
    }

    async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/v3/customer/bundle/userBundles/${transactionId}/razorpay-order`, data);
        return res?.data;
    }

    async fetchPaymentStatus(transactionId: number): Promise<TransactionV3> {
        const res = await HttpClient.get(`/v3/customer/bundle/razorpay-transactions/${transactionId}`);
        return res?.data;
    }

    async fetchAllActiveCoupons(userBundleMappingId: number): Promise<Coupon[]> {
        const res = await HttpClient.get(`/customers/v4/coupon/userBundles/${userBundleMappingId}`);
        return res?.data;
    }

    async verifyCoupon(verifyCoupon: VerifyCoupon): Promise<Coupon> {
        const res = await HttpClient.post('/customers/v4/coupon/verify', verifyCoupon);
        return res?.data;
    }

    async renewBundle(userBundleMappingId: number, payload: {bundleId: number, includeFreeTr: boolean} ): Promise<UserBundleMapping> {
        const res = await HttpClient.post(`/v3/customer/bundle/userBundles/${userBundleMappingId}/renew`, payload );
        return res?.data;
    }

    async getFullBundleDetails(userBundleMappingId: number): Promise<UserBundleMapping> {
        const res = await HttpClient.get(`/v3/customer/bundle/userBundles/${userBundleMappingId}/full-details`);
        return res?.data;
    }


    async getAllBundles(): Promise<any> {
        const res = await HttpClient.get(`/v3/customer/bundle`);
        return res?.data;
    }

}

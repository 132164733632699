import Customer from '../interfaces/customer';
import { UserToCookPayment } from '../interfaces/userToCookPayment/userCookPayment';
import useRazorpay from "react-razorpay";
import { RazorpayConstants } from './constants';

export class PaymentUtility {
  public Razorpay = useRazorpay();
  public async initatePaymentForCookPayment(
    userProfile: Customer,
    transactionDetails: UserToCookPayment,
    successCallBack: any,
    failureCallback: any,
  ) {
    if (transactionDetails && transactionDetails?.razorpayOrder) {
      let options = {
        description: 'Payment for Cook Monthly Salary',
        currency: RazorpayConstants.Currency,
        image: 'https://thechefkart.com/logo192.png',
        key: process.env.REACT_APP_RAZORPAY_KEY as string,
        amount: String(Number(transactionDetails?.amount) * 100),
        name: RazorpayConstants.CompanyName,
        retry: {
          enabled: false
        },
        order_id: transactionDetails?.razorpayOrder?.razorpayOrderId,
        prefill: {
          email: userProfile?.email || 'void@razorpay.com',
          contact: userProfile?.mobile,
          name: `${userProfile?.fname || ''} ${userProfile?.lname || ''}`,
        },
        theme: RazorpayConstants.Theme,
        handler: async function(response: any){
          await successCallBack(response, transactionDetails)
        },
        config: {
          display: {
            hide: [
              {
                method: 'paylater',
              },
              {
                method: 'emi',
              },
              {
                method: 'cardless_emi',
              },
              {
                method: 'card',
              },
              {
                method: 'wallet',
              },
            ],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
      };

      try {
        let razorpayData = new (window as any).Razorpay(options)
        razorpayData.on('payment.failed', async function (response: any){
          await failureCallback(response, transactionDetails)
        })
        razorpayData.open()
      } catch (error: any) {
        console.log(error)
      }
    }
  }
}

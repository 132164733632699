import { Dispatch } from "react";
import { CouponTypes } from "../enums/CouponTypesEnum";
import Coupon from "../interfaces/coupon";

const sessionStorage = window.sessionStorage

export const saveToSessionStorage = (key: string, value: any, isJson: boolean = false) => {
    if (isJson === true) {
        JSON.stringify(value)
    }

    sessionStorage.setItem(key, value);
}

export const retrieveFromSessionStorage = (key: string) => {
    return sessionStorage.getItem(key);
}

/**
 * Polls a function with the given time interval
 * @param fn Function to be polled
 * @param setResult Function to call and update with the return value of fn
 * @param interval Time in miliseconds
 */
export const poll = async (fn: any, setResult: Dispatch<React.SetStateAction<any>>, interval: number = 5000) => {
    return setInterval(async () => {
        const result = await fn();
        setResult(result);
    }, interval);
}

/**
 * Adds delay of the given time in an async operation
 * @param ms time in miliseconds
 * @returns void
 */
export const delay = (ms: number) => {
    return new Promise((resolve) => {
        const timeout: any = setTimeout(() => resolve(timeout), ms)
    })
}

export const getMealString = (meal: string) => {
    switch (meal) {
        case 'B':
            return 'Breakfast';
        case 'L':
            return 'Lunch';
        case 'D':
            return 'Dinner';
        case 'BL':
            return 'Breakfast + Lunch';
        case 'BD':
            return 'Breakfast + Dinner';
        case 'LD':
            return 'Lunch + Dinner';
        case 'BLD':
            return 'Breakfast + Lunch + Dinner'
        default:
            return meal
    }
}

export const calculateNetPrice = (amount: string, discountCoupon: Coupon): { discount: string, amount: string } => {

    if (!discountCoupon) {
        return { discount: "0.00", amount: amount }
    }

    let discountedAmount = 0

    if (discountCoupon.couponType === CouponTypes.FLAT) {
        discountedAmount = Number(discountCoupon.couponValue)
    }

    else if (discountCoupon.couponType === CouponTypes.PERCENT) {
        discountedAmount = (Number(discountCoupon.couponValue) * Number(amount)) / 100
    }

    if(discountedAmount > Number(discountCoupon.maximumDiscountAmount)){
        discountedAmount = Number(discountCoupon.maximumDiscountAmount)
    }

    let finalAmount = Number(amount) - discountedAmount

    return { discount: discountedAmount.toFixed(2), amount: finalAmount.toFixed(2) }
}

import HttpClient from "../HttpClient";
import {TransactionV3} from "../interfaces/transactionV3";
import Coupon from "../interfaces/coupon";
import RazorpayPayment from "../interfaces/razorpayPayment";
export default class TrialService {

    async getTrialDetails(trailId: number){
        const res = await HttpClient.get(`centaurus/v3/trials/${trailId}`);
        if (res?.status === 200){
            return res?.data;
        }

        return null;
    }

    async initiatePayment(trailId: number, couponCode?: number): Promise<TransactionV3> {
        let body = {}

        if (couponCode) {
            body = {
                discountCouponId: couponCode,
                version: 2, // this is hard-coded because of Coupon v2
            }
        }

        const res = await HttpClient.post(`/customers/v4/trials/${trailId}/razorpay-order`, body);
        return res?.data;
    }


    async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/customers/v4/trials/${transactionId}/razorpay-order`, data);
        return res?.data;
    }

    async fetchPaymentStatus(transactionId: number): Promise<TransactionV3> {
        const res = await HttpClient.get(`/customers/v3/trials/${transactionId}`);
        return res?.data;
    }

    async fetchAllActiveTrialCouponsByHouseId(houseId: number): Promise<Coupon[]> {
        const res = await HttpClient.get(`/customers/v4/coupon/trials/house/${houseId}`);
        return res?.data;
    }

    async verifyCoupon(verifyCoupon: {couponCode: string}): Promise<Coupon> {
        const res = await HttpClient.post('/customers/v4/coupon/trial/verify', verifyCoupon);
        return res?.data;
    }
}

import HttpClient from "../HttpClient";
export default class CookService {
    
    async getCookDetails(cookId: number){
        const res = await HttpClient.get(`/centaurus/user/cook/${cookId}`)

        if (res?.status === 200)
            return res?.data

        return null;
    }
}
import React  from 'react';

interface InputProps {
    title: string;
}

export default function CardTitle(props: InputProps) {
    const {title} = props;
    return (
        <div style={{ width: '100%' }}>
            <p style={{fontSize: 15, fontWeight: 500, padding: 16, paddingBottom: 8, margin: 0}}>{title}</p>
        </div>
    )
}

import HttpClient from "../HttpClient";
import { MarketPlaceRazorpayOrder } from "../interfaces/MarketPlaceRazorpayOrder";
import RazorpayPayment from "../interfaces/razorpayPayment";

export default class MarketPlaceService {
  async getMarketPlaceCookPrice() {
    const res = await HttpClient.get(
      `/cook-profile/market-place-order/single-cook-price`
    );

    return res?.data;
  }

  async createMarketPlaceOrder(
    marketPlaceOrderId: number,
    payload: { noOfCooks: number }
  ): Promise<MarketPlaceRazorpayOrder> {
    const res = await HttpClient.post(
      `/cook-profile/market-place-order/${marketPlaceOrderId}/razorpay-order`,
      payload
    );

    return res?.data;
  }

  async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
    const res = await HttpClient.patch(`/cook-profile/market-place-order/${transactionId}/razorpay-order`, data);
    return res?.data;
  }

  async getMarketPlaceOrderDetails(marketPlaceOrderId: number) {
    const res = await HttpClient.get(
      `/cook-profile/market-place-order/${marketPlaceOrderId}`
    );

    return res?.data;
  }
}

import React from 'react';
import IMG from '../assets/chef_kart_logo.png';

interface InputProps {
    title: string;
    secondary?: any;
 }

export default function AvatarCard(props: InputProps) {
    return (
        <div style={{ width: '100%', gap: 10, justifyContent: 'flex-start'  }} className='d-flex-center'>
            <img style={{ width: '15%', maxWidth: '40px', maxHeight: '40px' }} src={IMG} alt={"chefkart-logo"} />
            <div style={{ gap: 5, alignItems: 'flex-start' }} className='d-flex-column-center'>
                <p style={{ fontSize: '15px', textAlign: 'left', margin: 0 }}> {props.title} </p>
                <props.secondary />
            </div>
        </div>
    )
}

import React from 'react'
import IMG from "../assets/chef_kart_logo.png";
import { FirebaseCleverTabRecordEvent } from '../firebase';
import { EventNames } from '../firebase/EventNames';
import { ScreenNames } from '../firebase/ScreenNames';

export default function DownloadAppCard() {
    return (
        <div
            className='d-flex-center'
            style={{ width: '50%', gap: 10, boxShadow: '0px 0px 2px #D6D6D69E', padding: '10px' }}
        >
            <img style={{ width: '15%', maxWidth: '40px', maxHeight: '40px' }} src={IMG} alt={"chefkart-logo"} />
            <div
                style={{ gap: 5 }}
                className='d-flex-column-center'>
                <p
                    style={{ fontSize: '12px', textAlign: 'center', margin: 0 }}>
                    For a hasslefree and seamless experience
                </p>
                <button
                    style={{
                        fontSize: '11px',
                        color: 'white',
                        backgroundColor: 'black',
                        padding: '5px 15px 5px 15px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        border: 'none'
                    }}
                    onClick={() => { 
                        FirebaseCleverTabRecordEvent(EventNames.DOWNLOAD_APP_CLICKED, { screenName: ScreenNames.PAYMENT_POST_SUCCESS})
                        window.open("https://play.google.com/store/apps/details?id=com.chefkart.app", "_blank")
                    }}>
                    Download the App
                </button>
            </div>
        </div>
    )
}

import React from 'react';
import './formComponent.css'
interface InputProps {
    setName?: any;
    setMobile?: any;
    setAddress?: any;
    setAmount? : any;
    setSku?: any
    onSubmitCb?: any
}

export default function PromoFormComponent(props: InputProps) {
    return (
        <div
            style={{
                width: '100%',
                maxWidth: '500px'
            }}
        >
            <form  style={{display:'flex', flexDirection: "column"}} onSubmit={(event) => {
                    event.preventDefault();
                    console.log("clicked")
                    props.onSubmitCb();
                }}>
                <label htmlFor="customerName">Name: </label>
                <input type="text" name="customerName" id="customerName" required onChange={(e)=> {
                    props.setName(e.target.value);
                }}/>
                <label htmlFor="customerPhone">Mobile: </label>
                <input type="text" name="customerPhone" id="customerPhone" required onChange={(e)=> {
                    props.setMobile(e.target.value);
                }}/>
                <label htmlFor="address">Address: </label>
                <textarea
                    id="address"
                    name="address"
                    rows={4}
                    required
                    onChange={(e) => {
                        props.setAddress(e.target.value);
                    }}
                />
                <fieldset>
                    <legend>Select a product:</legend>
                    <div className="radioGroup">
                        <input type="radio" id="half" name="sku" required defaultChecked onChange={() => {
                            props.setAmount(849)
                            props.setSku("Anveshan Ghee 500 Grams")
                        }}/>
                        <label htmlFor="half">500 gram: Rs. 849  <s>999</s></label>
                    </div>

                    <div className="radioGroup">
                        <input type="radio" id="full" name="sku" onChange={() => {
                            props.setAmount(1657)
                            props.setSku("Anveshan Ghee 1 kg")
                        }}/>
                        <label htmlFor="full">1 Kg: Rs. 1657 <s>1949</s></label>
                    </div>
                </fieldset>
                <input type="Submit" value="Pay Now" onChange={()=>{}}/>
            </form>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import EventBus from "../EventBus";
import {EventBusEventsEnum} from "../enums/EventBusEventsEnum";

export interface CustomSnackbarProps {
    message: string,
    duration: number
}

const CustomSnackbar = () => {

    let [snackbarShow, setSnackbarShow] = useState<boolean>(false);
    let [message, setMessage] = useState<string>('No message')

    useEffect(() => {
        EventBus.getInstance().on(EventBusEventsEnum.SNACKBAR_SHOW, (data: CustomSnackbarProps) => {
            setSnackbarShow(true);
            setMessage(data.message)
            setTimeout(() => {
                setSnackbarShow(false)
                setMessage('No message')
            }, data.duration || 3000)
        })
    }, [])

    return (
        <>
            {
                snackbarShow &&
                <div
                    style={{
                        position: 'absolute',
                        left: '20px',
                        bottom: '20px',
                        borderRadius: '4px',
                        padding: '8px 18px',
                        backgroundColor: '#3295d6'
                    }}
                >
                    <p
                        style={{
                            margin: '0px',
                            color: '#ffffff'
                        }}
                    >
                        {message}
                    </p>
                </div>
            }
        </>
    );
};

export default CustomSnackbar;

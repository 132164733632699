import HttpClient from "../HttpClient";
import {TransactionV3} from "../interfaces/transactionV3";
import Coupon from "../interfaces/coupon";
import RazorpayPayment from "../interfaces/razorpayPayment";
import {ProductTypes} from "../enums/ProductTypesEnum";

export default class StarCleanerService {

    async getStarCleanerDetails(serviceId: number){
        const res = await HttpClient.get(`/centaurus/starcleaner/${serviceId}`);
        if (res?.status === 200){
            return res?.data;
        }
        return null;
    }

    async initiatePayment(serviceId: number, couponCode?: number): Promise<TransactionV3> {
        let body = {}
        if (couponCode) {
            body = {
                discountCouponId: couponCode,
            }
        }

        const res = await HttpClient.post(`/v1/customer/starcleaner/${serviceId}/razorpay-order`, body);
        return res?.data;
    }

    async updatePaymentStatus(transactionId: number, data: RazorpayPayment) {
        const res = await HttpClient.patch(`/v1/customer/starcleaner/${transactionId}/razorpay-order`, data);
        return res?.data;
    }

    async fetchAllActiveCouponsByHouseId(houseId: number, productType: ProductTypes): Promise<Coupon[]> {
        const res = await HttpClient.get(`/customers/v4/coupon/${productType}/houses/${houseId}`);
        return res?.data;
    }

    async verifyCoupon(verifyCoupon: {couponCode: string, productType: ProductTypes}): Promise<Coupon> {
        const res = await HttpClient.post('/customers/v4/coupon/verify/v2', verifyCoupon);
        return res?.data;
    }
}

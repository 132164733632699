export enum EventNames {
    USER_DETAILS_NAME_EDITED = "User details name edited",
    USER_DETAILS_MOBILE_EDITED = "User details mobile edited",
    USER_DETAILS_GET_OTP_CLICKED = "User details get otp clicked",
    USER_DETAILS_VERIFY_OTP_CLICKED = "User details verify otp clicked",
    USER_DETAILS_COOK_SALARY_EDITED = "User details cook salary edited",
    USER_DETAILS_PAY_NOW_CLICKED = "User details pay now clicked",
    DOWNLOAD_APP_CLICKED = "Download app clicked",
    OKANE_PAY_NOW_CLICKED = "Okane Pay Now clicked",
    OKANE_PAGE_VIEWED = "Okane Page Viewed",
    OKANE_COUPON_APPLIED = "Okane coupon applied",
    PURCHASE = "purchase"
}

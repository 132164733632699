import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PlanCard from "../components/PlanCard";
import { EventBusEventsEnum } from "../enums/EventBusEventsEnum";
import EventBus from "../EventBus";
import Plan from "../interfaces/plan";
import SubscriptionService from "../services/subscriptionService";
import {
  retrieveFromSessionStorage,
  saveToSessionStorage,
} from "../utils/utils";
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import UserBundleMapping from "../interfaces/userBundleMapping";

const subscriptionService = new SubscriptionService();

interface BundleInterface {
  id: number;
  name: string;
  title: string;
  description: string
}

export default function ChooseBundle() {
  const [bundles, setBundles] = useState<Plan[]>();
  const [includeFreeTr, setIncludeFreeTr] = useState<boolean>(true);
  const [showIncludeFreeTr, setShowIncludeFreeTr] = useState<boolean | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [saveSessionCount, setSaveSessionCount] = useState<boolean>(false)

  useEffect(() => {
    (async function () {
      try {
        subscriptionService.getAllBundles().then((res: BundleInterface[]) => {
          let bundle = res?.map((eachBundle: BundleInterface): Plan => {
            return {
              id: eachBundle?.id,
              name: eachBundle?.name,
              description: eachBundle?.title,
              termsDescription:
              eachBundle?.description,
            }
          })
          setBundles(bundle);
        })
      } catch (err: any) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: err?.response?.data?.error,
        });
      }

      const token = searchParams.get("token");
      const userBundleMappingId = searchParams.get("userBundleMappingId");

      if (token && userBundleMappingId) {
        saveToSessionStorage("token", token);
        saveToSessionStorage("userBundleMappingId", userBundleMappingId);
        setSaveSessionCount(true)
      } else {
        navigate("/expired");
      }
    })();
  }, [navigate, searchParams]);

  useEffect(() => {
    if (saveSessionCount) {
      (async function () {
        const res: UserBundleMapping =
          await subscriptionService.getFullBundleDetails(
            Number(searchParams.get("userBundleMappingId"))
          );
        setShowIncludeFreeTr(res?.includeFreeTr);
      })();
    }
  }, [saveSessionCount, searchParams, showIncludeFreeTr]);

  const onPlanSelect = async (bundle: any) => {
    try {
      const userBundleMappingId = retrieveFromSessionStorage(
        "userBundleMappingId"
      );

      const userBundleMapping = await subscriptionService.renewBundle(
        parseInt(userBundleMappingId!),
        {
          bundleId: bundle.id,
          includeFreeTr,
        }
      );
      const token = retrieveFromSessionStorage("token");

      navigate(
        `/pay?token=${token}&userBundleMappingId=${userBundleMapping.id}`
      );
    } catch (err) {
      navigate("/expired");
    }
  };

  return (
    <div
      className="d-flex-column-center"
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "500px",
        justifyContent: "flex-start",
        // padding: 20,
      }}
    >
      <TopBar title={"Choose Plan"} />
      <div
        style={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <CardTitle title={"Available Plans"} />
        <div className="d-flex-column-center" style={{ gap: 15 }}>
          {bundles &&
            bundles
              ?.filter((e) => e.id !== 2)
              .map((bundle) => (
                <PlanCard
                  key={bundle.id}
                  name={bundle.name}
                  description={bundle.description}
                  termsDescription={bundle.termsDescription}
                  onActionClick={() => onPlanSelect(bundle)}
                />
              ))}
          {showIncludeFreeTr === false && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                boxShadow: "0px 1px 4px #D6D6D69E",
                padding: 20,
                width: "80%",
                borderRadius: "9px",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <p style={{ fontSize: "15px", fontWeight: "500" }}>
                  Choose New Pricing? :{" "}
                </p>
              </div>
              <label htmlFor="yes">
                <div style={{ display: "flex", marginRight: "0.5rem" }}>
                  <input
                    onChange={() => {
                      setIncludeFreeTr(true);
                    }}
                    checked={includeFreeTr}
                    id="yes"
                    style={{ marginRight: "0.2rem", accentColor: "green" }}
                    type="checkbox"
                  />
                  <div>
                    <p style={{ fontSize: "15px", fontWeight: 400 }}>
                      Yes
                      <span style={{ fontSize: "12px", color: "#8A8A8A" }}>
                        (Includes Free TR)
                      </span>
                    </p>
                  </div>
                </div>
              </label>
              <label htmlFor="no">
                <div style={{ display: "flex" }}>
                  <input
                    onChange={() => {
                      setIncludeFreeTr(false);
                    }}
                    checked={!includeFreeTr}
                    id="no"
                    style={{ marginRight: "0.2rem", accentColor: "green" }}
                    type="checkbox"
                  />
                  <div>
                    <p style={{ fontSize: "15px", fontWeight: 400 }}>No</p>
                  </div>
                </div>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
